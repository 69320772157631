.w-20 {
  width: 20%;
  flex: 0 0 auto;
}
.slider .slide-description {
  align-items: end;
}
.custom-minus {
  position: absolute;
  left: 0;
  cursor: pointer;
  /* top: 75%; */
}

.custom-plus {
  position: absolute;
  right: 0;
  cursor: pointer;
  /* top: 75%; */
}
.custom-blockquote {
  font-family: Georgia, serif;
  position: relative;
  margin: 0.5em;
  padding: 0.1em 2em 0.1em 3em;
}

.custom-blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 3em;
  line-height: 1;
  top: 0;
  left: 0;
  content: "\201C";
}
.custom-blockquote:after {
  font-family: Georgia, serif;
  position: absolute;
  float: right;
  font-size: 3em;
  line-height: 1;
  right: 0;
  bottom: -0.5em;
  content: "\201D";
}
.custom-blockquote footer {
  padding: 0 2em 0 0;
  text-align: right;
}
.custom-blockquote cite:before {
  content: "\2013";
}
.custom-blockquote h6 {
  line-height: 30px;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.custom-description {
  position: absolute;
  width: 60pc;
  top: 200px;
}
.m-description-parent-element {
  margin-bottom: 172px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
.slick-next:before,
.slick-prev:before {
  color: #4c4545;
}

@media only screen and (max-width: 420px) {
  .slide-0.w-20:not(.true) {
    display: none;
  }
  .slide-0.w-20.true {
    display: block;
    width: 100%;
  }
}
