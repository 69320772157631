* {
  box-sizing: border-box;
}

#root {
  overflow-x: clip;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  overflow-x: hidden;
}
.clickable {
  cursor: pointer;
}

#navbarNavDropdown .nav-link {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1.1px;
  color: white;
}

.setcontent {
  padding-left: 2.1rem !important;
}

.dropdown-item:hover {
  background-color: transparent !important;
  color: white !important;
}

.dropdown-item {
  position: relative;
  display: inline-block;
  transition: color 0.2s ease;
  font-size: 14px;
  padding: 0px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
}

.letterSpacing {
  letter-spacing: 1px;
}

.dropdown-item:hover::after {
  width: 100%;
  left: 0;
}

.dropdown-item::after {
  content: "";
  position: absolute;
  top: calc(100%);
  width: 0;
  right: 0;
  height: 3px;
  border: 1px solid black;
  transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
  background: white;
}

.form-control {
  font-size: 14px !important;
  appearance: auto !important;
}

.NavBgColor {
  background-color: #000000;
}

.dropdown-menu {
  --bs-dropdown-bg: black !important;
  --bs-dropdown-link-color: white !important;
  padding: 0px !important;
  max-width: -webkit-max-content;
  max-width: max-content;
  padding: 5px !important;
  width: 60vw;
}

.dropdown-menu > li {
  padding: 9px 8px 8px 10px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.nav-link {
  font-size: 14.5px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fontfff {
  color: white;
}

.video-parent {
  width: 100%;
  height: 118vh;
  background-color: #2c2c2cd4;
  border-radius: 15px;
  position: absolute;
  z-index: 999;
}

.videoCloseIcon {
  position: absolute;
  right: 141px;
  top: 27px;
  font-size: 24px;
  cursor: pointer;
  z-index: 9999;
  color: white;
}

.stopScroll {
  overflow: hidden;
}

.videoContainer {
  position: absolute;
  top: 10%;
  left: 15%;
  bottom: 15%;
  right: 15%;
}

.contactAdd {
  font-size: 18px;
  font-weight: 600;
}

.aboutsusBtn {
  background: #ffffff !important;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: 600;
  color: black;
  font-family: "Poppins", sans-serif !important;
}

.aboutsusBtn:hover {
  background: #ee720b !important;
  color: white;
  transition: 0.9s;
}
.web-dev-Btn {
  background: #203f59 !important;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: 600;
  color: white;
  font-family: "Poppins", sans-serif !important;
}

.web-dev-Btn:hover {
  background: #ee720b !important;
  color: white;
  transition: 0.9s;
}

.is-sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.bgF7F7F7 {
  background: #f7f7f7 !important;
}
.ColorNavbar {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}
.colorWhite {
  color: white !important;
}
.SlideTextDiv {
  position: absolute;
  top: 10%;
  left: 5%;
  color: white;
  font-family: "Poppins", sans-serif !important;
}

.newpx-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.pxx-5 {
  padding-left: 3rem !important;
}

.pt0class {
  padding-top: 3rem !important;
}

@media only screen and (max-width: 430px) {
  .imagetimer2 {
    margin-left: 0px !important;
  }
  .custom-blockquote2 {
    font-family: Georgia, serif !important;
    position: relative !important;
    margin: 0.5em !important;
    padding: 0.1em 2em 0.1em 3em !important;
  }

  .custom-blockquote2:before {
    font-family: Georgia, serif !important;
    position: absolute !important;
    font-size: 3em !important;
    line-height: 1 !important;
    top: -30px !important;
    left: 40px !important;
    content: "\201C" !important;
  }

  .custom-blockquote2:after {
    /* font-family: Georgia, serif;
    position: absolute;
    float: right;
    font-size: 3em;
    line-height: 1; */
    right: 123px !important;
    bottom: -50px !important;

    /* content: "\201D"; */
  }

  .outer-header-ui-ux {
    display: block !important;
  }

  .inner-header-ui-ux {
    display: none !important;
  }

  .minusmargin {
    flex-direction: column-reverse;
  }

  .pt0class {
    padding-top: 0 !important;
  }

  .btnmargin-for-mobile2 {
    width: 188px !important;
  }

  .pt-5.padding-for-mobile {
    padding-top: 3px !important;
  }

  .py-4.padding-for-mobile2 {
    padding-top: 34px !important;
  }

  .pt-5.padding-for-mobile3 {
    padding-top: 1px !important;
  }

  .px-5.pxformob {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .px-5.padding-for-mobile4 {
    padding-top: 50px;
  }

  .py-4.padding-for-mobile {
    padding-top: 3px;
  }
}

@media only screen and (max-width: 600px) {
  .SlideTextDiv {
    position: absolute;
    top: 10%;
    left: 8%;
    color: white;
    font-family: "Poppins", sans-serif !important;
  }

  .newpx-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .what-clint-think {
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    flex-wrap: wrap;
  }

  .imagetimer {
    margin-right: 2px !important;
  }

  .imagetimer img {
    width: -webkit-fill-available;
  }

  .imagetimer2 {
    margin-right: 2px !important;
    margin-left: 0px !important;
  }

  .imagetimer2 img {
    width: -webkit-fill-available;
  }

  .gocoworq-detail .content-container {
    padding-top: 10px !important;
    padding-left: 70px !important;
  }

  .portfolio-ui-design {
    font-size: 18px !important;
    width: 152px !important;
  }
}

@media (max-width: 1024px) {
  .method-block-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 0 !important;
  }
  .method-block-row .method-block-col {
    width: 100% !important;
    padding: 0 !important;
  }
  .method-block-row .method-block-col .method-content {
    margin-bottom: 32px !important;
    padding: 23px 20px 30px;
    border: 1px solid #e5e5e5;
    text-align: left;
    border-radius: 10px;
  }
  .method-block-row .method-block-col .method-content .item-header {
    display: flex;
    -webkit-box-pack: justify !important;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .method-block-row .method-block-col .method-content h3 {
    margin: 0;
    width: 180px;
  }
  .method-block-row
    .method-block-col
    .method-content
    .item-header
    .icon-wrapper {
    display: flex;
    -webkit-box-align: center;

    align-items: center;
    -webkit-box-pack: center;

    justify-content: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;

    box-shadow: 0px 8px 8px rgb(0 0 0 / 7%);
  }
  .d-none-for-mobile {
    display: none;
  }
  .margin-for-mobile {
    margin-right: 200px;
  }
}

@media (min-width: 601px) and (max-width: 767px) {
  .SlideTextDiv {
    position: absolute;
    top: 10%;
    left: 8%;
    color: white;
    font-family: "Poppins", sans-serif !important;
  }

  .newpx-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.blackBg {
  background-color: black;
  /* height: 88vh;
  background-image: url("/public/images/homeHeroBgImg.svg"); */
  width: 100%;
}

.h-8 {
  height: 8px !important;
}

.h-13 {
  height: 13vh !important;
}

/* The typing effect */
.typewriter h4 {
  max-width: max-content;
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  /* margin: 0 auto; */
  /* letter-spacing: 0.13em; */
  font-weight: 600;
  animation: typing 3.8s steps(40, end), blink-caret 0.5s step-end;
}

.h1Tag h1 {
  font-size: 20px !important;
  font-weight: 400 !important;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif !important;
  color: #0e2b3d;
}

.heroTitle h1 {
  font-size: 1.6em !important;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 1rem !important;
}

.clr0E2B3D {
  color: #0e2b3d;
}
.bgCl0E2B3D {
  background-image: url("/public/images/ApiIntegrationQuoteBg.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 362px;
  border-bottom-left-radius: 254px;
}
.cl2D4BE8 {
  color: #2d4be8;
}
.image-pos-right {
  flex-direction: row-reverse;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

/* clients section */
logo-slider {
  padding: 10px;
  overflow: hidden;
  /* max-width: 1250px; */
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}

logo-slider div {
  display: flex;
  /* position: relative; */
  animation: marquee 30s linear infinite;
  /* justify-content: space-around; */
}

logo-slider img {
  display: block;
  min-width: 70px;
  margin: 0 3vw;
}
logo-slider {
  padding: 10px;
  overflow: hidden;
  /* max-width: 1250px; */
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  margin-top: 20px;
}

logo-slider div {
  display: flex;
  /* position: relative; */
  animation: marquee 30s linear infinite;
  /* justify-content: space-around; */
}

logo-slider img {
  display: block;
  min-width: 70px;
  margin: 0 3vw;
}
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* portfolio section */
.page {
  box-sizing: border-box;
  height: auto;
  position: sticky;
  top: 12%;
}

.fs {
  /* background-color: red; */
  z-index: 2;
}

.sc {
  /* background-color: blue; */
  z-index: 3;
}

.th {
  /* background-color: green; */
  z-index: 4;
}

.fr {
  /* background-color: yellow; */
  z-index: 5;
}

/* service section */

.seticonleft {
  padding-left: 3.5rem !important;
}
.seticonright {
  padding-right: 2.5rem !important;
}

@media screen and (max-width: 576px) {
  .leftPortfolioPara {
    padding-left: 3rem;
    padding-top: 4rem;
  }

  a.servises_box::after {
    content: none !important;
  }

  .newpx-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .alignItemsCenter {
    align-items: baseline !important;
  }
}
.alignItemsCenter {
  align-items: center;
}

@media screen and (max-width: 991px) {
  .leftPortfolioPara {
    padding-left: 3rem;
    padding-top: 4rem;
  }
  a.servises_box {
    max-width: 95%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 991px) {
  .mpcClientDiv {
    padding: 3% 4% 0% 13% !important;
    /* background-size: cover; */
    background-color: #f5f4eabf;
    border-radius: 11px;
  }
}
@media screen and (max-width: 767px) {
  .headerFontSizeH6 {
    font-size: 24px !important;
  }
  .textalignleft {
    text-align: left !important;
  }
  .setfontsize {
    font-size: 11px !important;
  }
  .seticonleft {
    padding-left: 1rem !important;
  }
  .seticonright {
    padding-right: 1rem !important;
  }

  /* .SetContentPadding {
    padding: 1rem 0.5rem 1rem 1rem!important;
  } */
  .Removepadding {
    padding-right: 0px !important;
  }
  .setcontent {
    padding: 0px !important;
  }
  .setbtn.CaseStudydBtn {
    background-color: #203f59 !important;
    border: 1px solid white;
    color: white !important;
    font-size: 12px !important;
    width: 90%;
    /* padding-left: 5px !important;
    padding-right: 5px !important; */
    margin: 0 auto;
  }
  .phoneMargin0 {
    margin: 0 !important;
  }
  .Port_Bg {
    /* height: -webkit-fill-available !important; */
    background-position: top !important;
    background-size: cover !important;
  }
  .bg-freecon {
    background-image: url("../../public/images/mobile-plane.svg") !important;
    padding-top: 105px !important;
  }
  .btn-plane {
    padding: 10px !important;
    margin-top: 20px !important;
  }
  .bor-card1 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .headerColorAndSize {
    font-size: 22px !important;
  }
  .customheader {
    font-size: 20px !important;
  }
  .font30 {
    font-size: 19px !important;
    font-weight: 600 !important;
    line-height: 30px !important;
  }
  .listColorAndSize {
    font-size: 15px !important;
    line-height: 24px !important;
    text-align: justify;
  }
  .labelColorAndSize {
    font-size: 15px !important;
    text-justify: distribute-all-lines;
  }
  .divColorAndSize {
    font-size: 15px !important;
    text-justify: distribute-all-lines;
  }

  .port-img-text {
    left: 4px !important;
  }
  .wid-7,
  .wid-9,
  .wid-11 {
    width: 30% !important;
    display: flex !important;
    justify-content: center !important;
  }
  .height70 {
    height: 70px !important;
  }
  .hero-card-txt {
    padding-left: 0rem !important;
  }
  .joint-vector {
    width: 100% !important;
  }
  .pb50 {
    padding-bottom: 0px !important;
  }
  .bor-card {
    border-radius: 10px !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .hero-card-heading {
    font-size: 20px !important;
  }
  .hero-card-heading10 {
    font-size: 17px !important;
  }
  .setlineheight {
    line-height: 1.3 !important;
  }
  .setheader {
    color: white;
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
  }
  .mobile-width {
    width: -webkit-fill-available;
  }
  .h1Tag {
    font-size: 16px !important;
    margin: 0 auto;
    margin-left: 50px;
    margin-right: 50px;
  }
  .hoverBtnMobileEffect {
    width: 160px !important;
    height: 35px !important;
  }
  .NewServicePara.setmobilecontent .ps-0 {
    font-size: 20px;
    font-weight: 700 !important;
    margin: 0;
  }
  .leftPortfolioPara {
    padding-left: 3rem;
    padding-top: 4rem;
  }

  a.servises_box {
    max-width: 360px;
    margin: 0 auto !important;
    left: 0;
    position: relative;
    box-shadow: none;
    border: 1px solid #efefef !important;
    border-bottom: 4px solid #e6e5e5 !important;
  }

  a.servises_box::after {
    content: none !important;
  }
  .recentPPCards .pfbrdrtop {
    border-radius: 10px 10px 0px 0px;
  }
  .recentPPCards .pfbrdrbtm .newportpadding,
  .recentPPCards .pfbrdrbtm {
    border-radius: 0px 0px 10px 10px;
  }
  .setmobileicon .img-fluid.crsr-pointer {
    max-width: 10%;
    width: 10%;
  }
  .setctaimg .image-fluid {
    height: 75% !important;
  }
  .setdivcontent {
    text-align: justify;
  }
  .k12Header {
    font-size: 20px !important;
  }
  .col21 {
    width: 14.666667% !important;
  }
  .sideSectionlabel {
    font-size: 24px !important;
  }
  .sideSection {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .k12Label {
    font-size: 25px !important;
    font-weight: 600 !important;
  }
  .k12Body {
    line-height: 20px !important;
    font-size: 20px !important;
    display: block !important;
  }
  .k12para {
    font-size: 14px !important;
  }
  .k12text {
    font-size: 13px !important;
  }
  .wid-15 {
    width: 33% !important;
  }
  .techLogo {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100% !important;
    height: 100% !important;
  }
  .blurFont {
    font-size: 50px !important;
    top: -20px !important;
    left: 40px !important;
  }
  .k12HeaderSide {
    font-size: 26px !important;
    text-align: center !important;
  }
  .sprigK12ParaLabel {
    font-size: 14px !important;
  }
  .sprigK12Para {
    font-size: 14px !important;
  }
  .sideText {
    font-size: 30px !important;
    text-align: center;
  }
  .backPanel {
    font-size: 33px !important;
    top: 25px !important;
    left: 59px !important;
  }
  .backPanel1 {
    font-size: 33px !important;
    top: 25px !important;
    left: 40px !important;
  }
  .sideSectionParaLabel {
    font-size: 16px !important;
  }
  .sideSectionPara {
    font-size: 14px !important;
  }
  .bgBlackHeader {
    font-size: 26px !important;
    line-height: 40px !important;
  }
  .bgBlackPara {
    font-size: 15px !important;
  }
  .imageHeader {
    font-size: 18px !important;
  }
  .imagePara {
    font-size: 15px !important;
  }
  .port-hero-text {
    font-size: 20px !important;
    line-height: 30px;
  }
  .port-hero-text1 {
    font-size: 20px !important;
  }
  .port-hero-text2 {
    font-size: 16px !important;
  }
  .port-hero-text3 {
    font-size: 16px !important;
  }
  .bor-card {
    border: none !important;
  }
  .technology {
    font-weight: 600;
    color: #0e2b3d;
    font-size: 14px !important;
  }
  .ss-para {
    font-weight: 400;
    color: black;
    font-size: 12px !important;
  }
  .mpc-idea {
    font-weight: 600;
    color: #b73d46;
    font-size: 16px !important;
  }
  .mpcBtn {
    background: #b73d46 !important;
    border-radius: 14px !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 12px !important;
  }
  .hide-arrows {
    display: none;
  }
  .mpcImg {
    margin-left: 0% !important;
    margin-top: -74px;
  }
  .mpcClientTxt {
    font-weight: 400;
    color: black;
    font-size: 11px !important;
  }
  .play-button {
    position: absolute;
    top: 5% !important;
    left: 52% !important;
    transform: translate(-50%, -50%);
  }
  #content-div {
    display: flex;
    flex-direction: column-reverse;
  }
  .descText {
    font-size: 11px !important;
  }
  .ecommerceHeader {
    font-size: 22px !important;
  }
  .adjsutImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 85%);
    z-index: 1;
  }
  .techMpcFont {
    font-size: 12px !important;
    font-weight: 500;
    line-height: 24px;
  }
  .mpc-animation {
    height: 260px !important;
  }
  .custom-padding {
    padding: 15px 15px 30px 15px !important;
  }
  .mpcClientDiv {
    padding: 30% 0% 0% 0% !important;
    background-size: cover;
    background-color: #f5f4eabf;
    border-radius: 11px;
  }
  .mpc-blockquote:before {
    font-family: Georgia, serif;
    position: absolute;
    font-size: 8em;
    line-height: 1;
    top: -12px !important;
    left: 42px !important;
    content: "\201C";
    color: #d6656d1f;
  }
  .freeQuoteModal {
    display: flex;
    flex-direction: column-reverse;
  }
  .serviceDiv {
    padding: 2.7rem 0.8rem !important;
  }
  .refdiv {
    background-image: url("/public/images/referral-bg-mobile.png") !important;
    padding: 14% 2% 14% 2% !important;
  }
  .ss-ref {
    max-height: 55px !important;
  }
  .refHeading {
    font-size: 20px !important;
  }
  .refText {
    font-size: 11px !important;
  }
  .OrText {
    font-size: 23px !important;
  }
  .discount {
    font-size: 25px !important;
  }
  .clientdiv {
    padding: 38px 0px 38px 8px !important;
    font-size: 14px !important;
  }
  .refFormText {
    font-size: 18px !important;
  }
  .serviceTxt {
    font-size: 18px !important;
  }
  .custom-pe-2 {
    padding-right: 0.5rem !important;
  }
  .custom-pe-0 {
    padding-right: 0 !important;
  }
  .crmheading {
    font-size: 18px !important;
  }
  .crmTechnology {
    font-size: 14px !important;
  }
  .zoom-container {
    padding-left: 3rem !important;
    padding-right: 1.5rem !important;
  }
  .ecommerceSolutionHeader {
    font-size: 16px !important;
  }
  .ecommerceSolutionDesc {
    font-size: 13px !important;
  }
  .ecommerceDiv {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  /* .videoClose {
  position: absolute;
  right: 12px !important;
  top: 20px !important;
  font-size: 24px;
  cursor: pointer;
  z-index: 9999;
  color: white;
} */

  /* .setmobilepadding {
    margin: 10px;
  } */
}

@media screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu.desktop {
    display: block;
  }
  .contentContainer {
    padding-left: 4.5rem !important;
  }
  .ecommerceDiv {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }
}

.servises_box {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  display: block;
  transition: 450ms ease-in-out;
  height: auto;
  position: relative;
  border-radius: 10px;
  border-bottom: 4px solid #dedede;
  max-width: 88%;
  text-decoration: none !important;
}

a.servises_box::after {
  content: " ";
  width: 95%;
  height: 95%;
  z-index: -3;
  position: absolute;
  top: auto;
  bottom: 1px;
  left: 1px;
  background-color: #ee720b;
  background-repeat: repeat-x;
  transition: all 0.5s;
  border-radius: 10px;
}

.active_expertise:after,
a.servises_box:hover::after {
  transform: rotate(2deg) translateX(-5px) translateY(16px);
  -webkit-transform: rotate(2deg) translateX(-5px) translateY(16px);
  -ms-transform: rotate(2deg) translateX(-5px) translateY(16px);
  -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 30%);
  box-shadow: 0 10px 20px rgb(0 0 0 / 30%);
}

.clr0E2B3D {
  color: #0e2b3d;
}

.IS_Container {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  /* text-align-last: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  place-content: center;
  background-color: white;
}

.IS_Container.active {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
  border-radius: 12px;
}

.LifeSS_Container {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  /* text-align-last: center; */
  /* display: flex;
  flex-direction: column;
  place-content: center; */
  background-color: white;
}

.LifeSS_Container.active {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
  border-radius: 12px;
}

.LS_Containers {
  display: flex;
  justify-content: flex-start;
  gap: 2em;
}

.LS_Containers .col-3 {
  width: 265px;
  height: 327px;
}

.LS_imgBor {
  border-radius: 12px;
}

.LS_divImgBor {
  border-radius: 12px;
}

.ImgBorOpacity {
  opacity: 0.2;
}
.AU_Box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(32, 63, 89, 0.25);
  border-radius: 14px;
  border-left: 10px solid #203f59;
  position: relative;
  /* cursor: pointer; */
}
/* .AU_Box:hover::after {
  width: calc(100% - 10px);
}
.AU_Box::after {
  content: '';
  height: 100%;
  width: 3px;
  background: #203F59;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
} */
/* .AU_Box:hover .BlueClass{
  color: white !important;
}
.AU_Box:hover{
  animation: mymove 5s infinite;
  /* animation-timing-function: linear; */
/* animation-delay: 2s; */
/* } */
/* @keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
}  */

.AU_Box.active {
  background: #0e2b3d;
  box-shadow: 0px 5px 10px rgba(32, 63, 89, 0.25);
  border-radius: 14px;
}

.AU_Box .col-9 {
  display: flex;
  flex-direction: column-reverse;
}

.BradCrumbContainer {
  background: white;
  border-radius: 14px;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.textandItemCenter {
  align-items: center;
  text-align: center;
}
.h-245 {
  height: 245px !important;
}

.justifyCenter {
  justify-content: center;
}

.serviceBodyFonts {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justify;
  color: #606066;
}
.addMargin {
  margin: auto;
}

.processWeFollowFonts {
  color: #252b33;
  font-size: 15px;
  font-weight: 400;
  hyphens: auto;
  -webkit-hyphens: auto;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: justify;
  word-spacing: -0.02em;
}

.recentWorkHeader {
  font-size: 19px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.recentWorkBody {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #203f59;
}

.whChooseUseHeader {
  font-size: 23px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.whChooseUseBody {
  font-size: 17px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
}

.devOpsUl {
  list-style-position: inside;
}

.fontSizeEighteen,
.devOpsUl li {
  font-size: 18px;
  font-weight: 500;
}

.devOpsUl_copy {
  font-size: 18px;
  font-weight: 500;
  text-align: justify;
}

.webDpManImage {
  height: 258px;
}

.fontPoppins {
  font-family: "Poppins", sans-serif !important;
}

.fontPoppins2 {
  font-family: "Poppins", sans-serif !important;
  text-align-last: center;
}

.ServicePara {
  font-weight: 500;
  font-size: 16px;
  color: black;
  text-align: justify;
}

.NewServicePara {
  font-weight: 700;
  font-size: 20px;
  /* line-height: 14px; */
  color: black;
  /* text-align: justify; */
}

.bold-h3 h3 {
  font-weight: 700;
}

.AU_Font5 {
  font-weight: 600;
  font-size: 22px;
  color: #30303c;
}

.AU_Font6 {
  font-weight: 500;
  font-size: 16px;
}

.AU_Font7 {
  font-weight: 700;
  /* font-size: 32px; */
  color: black;
  line-height: 49px;
}

.AU_NewFont {
  font-weight: 700;
  /* font-size: 34px; */
  color: #30303c;
}

.AU_Font8 {
  font-weight: 600;
  font-size: 44px;
}

.HS_Font1 {
  font-weight: 800;
  font-size: 44px;
}
.HS_Font2 {
  font-weight: 500;
  font-size: 16px;
}

.C_font1 {
  font-weight: 600;
  font-size: 44px;
}

.devTags h1 {
  color: #203f59;
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
}

.C_font12 {
  font-weight: 700;
  font-size: 45px;
}

.ServiceHeader {
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
}

.customTP-Col,
.customTS-Col {
  flex: 0 0 auto;
  width: 20% !important;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

.deskDispFlex {
  display: flex;
}
.NwPlaceFont {
  font-weight: 600;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .newCarrerClass {
    height: 200px !important;
  }
  .LS_Containers .col-3 {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
  }
  .img-fluidM {
    max-width: 100%;
    height: auto;
  }

  .col6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .roww {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex !important;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }

  .leftPortfolioPara {
    padding-left: 3rem !important;
    padding-top: 4rem !important;
  }

  .deskDispFlex {
    display: block;
  }

  .C_font1 {
    font-weight: 600;
    font-size: 25px;
    text-align: center;
  }

  .customTP-Col {
    width: 100% !important;
  }

  .customTS-Col {
    width: 50% !important;
  }

  .wordBreakM {
    word-break: break-all;
  }

  .CU_InputSize input {
    width: 100% !important;
  }

  .CU_UL > :nth-child(3) {
    width: 100% !important;
  }

  .AU_Font7 {
    font-weight: 700;
    font-size: 28px !important;
    color: #30303c;
  }

  .hoverBtnMobileEffect {
    border-radius: 1.625rem;
    border: 2px solid #203f59;
    width: -webkit-fill-available;
    height: 3rem;
    font-size: 16px;
    font-family: inherit;
    color: #203f59;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  .crmBtnMobileEffect {
    border-radius: 1.625rem;
    border: 2px solid white;
    width: -webkit-fill-available;
    width: 160px !important;
    height: 35px !important;
    font-size: 16px;
    font-family: inherit;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }

  .fileUploadPadding {
    padding: 0px !important;
  }

  .HS_Font1 {
    font-size: 35px;
  }
  .HS_Font2 {
    font-size: 13px;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .text-m-center li {
    text-align: center;
  }
}

.C_UL {
  list-style-image: url("/public/images/C_ULIcon.svg");
}

.C_phpUL {
  /* list-style-image: url("/public/images/C_phpIcon.svg"); */
  list-style: decimal;
}

.C_phpUL li {
  font-weight: 600;
  font-size: 18px;
  color: #30303c;
  margin: 10px 0;
}

.companyAddress {
  font-weight: 600;
  font-size: 18px;
}

/* .C_UL li::marker {
  font-size: 10px;
  margin-right: 1px !important;
}  */

@media only screen and (min-width: 700px) {
  .C_phpSpace {
    display: flex;
    justify-content: space-between;
  }
}

.phpModalBtn {
  /* width: -webkit-fill-available; */
  background-color: #ee720b !important;
  border: 1px solid #ee720b !important;
  border-radius: 47px !important;
}

.CU_UL li {
  margin: 20px 0px;
  font-weight: 600;
  font-size: 16px;
  list-style: none;
}

/* .CU_UL > :nth-child(1) {
  list-style-image: url("/public/images/CUPhoneIcon.svg");
} */

/* .CU_UL > :nth-child(2) {
  list-style-image: url("/public/images/CUMessageIcon.svg");
}

.CU_UL > :nth-child(3) {
  width: 50%;
  list-style-image: url("/public/images/CULocationIcon.svg");
} */

.CU_InputSize input {
  width: 100%;
  height: 74.46px;
}

.freeQuoteBtn {
  background-color: white;
  border-radius: 30px;
  font-size: 13px;
  padding: 6px 13px 6px 13px;
  border: none;
}

.FFFPersonalize {
  background: white;
  border: 1px solid #a49f9d;
  border-radius: 30px;
  color: black;
}

.cffffff {
  color: #ffffff;
}

.personalizedBtn {
  background: #ffffff !important;
  border-radius: 14px !important;
  color: #ee720b !important;
  font-weight: 700 !important;
  font-size: 17px !important;
}

.CaseStudydBtn {
  background: #ee720b !important;
  border-radius: 14px !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 17px !important;
}

.cA49F9D {
  background-image: linear-gradient(
    to right,
    white,
    #a49f9d,
    #a49f9d,
    #a49f9d,
    #a49f9d
  );
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 362px;
  border-bottom-left-radius: 254px;
}

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}
.px-7 {
  padding-right: 3.5rem !important;
  padding-left: 4.5rem !important;
}

@media (min-width: 992px) {
  .px-lg-6 {
    padding-right: 4rem !important;
    padding-left: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  .px-lg-7 {
    padding-right: 3.6rem !important;
    padding-left: 4.8rem !important;
  }
}

@media (max-width: 992px) {
  .px-sm-6 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important;
  }
}

.activeNav {
  content: "";
  color: #ff9a48 !important;
  display: block;
  margin: auto;
  background: transparent;
  border-bottom: 3px solid #ff9a48 !important;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.adminlink:after {
  content: "";
  color: #ff9a48 !important;
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.adminlink:hover:after {
  width: 100%;
  background: #ff9a48 !important;
}

.w-65 {
  width: 65% !important;
}

.w-77 {
  width: 77% !important;
}

.nav-link:hover {
  color: #ff9a48 !important;
  cursor: pointer;
}

.mt-2Point5 {
  margin-top: 3.8rem !important;
}

.personalizedImageDivcol-5 {
  /* border: 1px solid #a49f9d; */
  position: relative;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 313px;
  top: 1px;
}

.homePageGYB {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* need to check merge */
/* 
  background: #FF9A48 !important;
  color: #FF9A48 !important;
} */

.nav-link:hover {
  color: #ff9a48 !important;
  cursor: pointer;
}

.personalizedImageDivcol-5 {
  position: absolute;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  top: 14%;
  /* width: 35%; */
  left: 7%;
}

/* rocket animation */

.pdd-font1 {
  font-weight: 600;
  font-size: 20px;
  color: #263238;
}

.footerLinkColor label {
  color: #fff;
  letter-spacing: 0.6px;
}

.justify-contentSpaceBtw {
  justify-content: space-between !important;
}

.Cl00671C {
  color: #00671c;
}
.cl3CBBA5 {
  color: #3cbba5;
}
.clA4894B {
  color: #a4894b;
}
.Clff7700 {
  color: #ff7700;
}

.Cl7C331E {
  color: #7c331e;
}
.clB60117 {
  color: #b60117;
}
.Cl0090E5 {
  color: #0090e5;
}

.pfFont-1 {
  font-weight: 600;
  font-size: 32px;
}

/* portfolio navigation menu */
.navLink {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #cccccc;
  letter-spacing: 0.5px;
}

.ActiveNavLink {
  border-bottom: 1px solid #ee720b !important;
}

.ActiveNavLink .navLink {
  color: black !important;
}

.fontColorBlue {
  color: #203f59;
}

.leftPortfolioPara {
  padding-left: 8rem;
  padding-top: 4rem;
}

.navLink:hover {
  color: #ee720b;
}

.DevCards {
  background: #f7f7f7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.aufont2 {
  font-weight: 500;
  font-size: 16px;
}

.auContainer {
  background-color: #0e2b3d;
}

.transperancyEthicsHeader {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  color: #203f59;
}

.transperancyEthicsBody {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #52525d;
}

.aboutUsIndImage {
  height: 204px;
}

.headerFontSizeH6 {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-transform: capitalize;
}

.headerFontSizeH3New {
  font-size: 24px;
  font-weight: 400;
}

.headerFontSizeH3NewBold {
  font-size: 24px;
  font-weight: 600;
}

.headerFontSizeH3 {
  font-size: 20px;
  font-weight: 400;
}

.aboutUsHeader {
  font-size: 28px;
  font-weight: 600;
}

.TpPositionFont {
  font-weight: 400;
  font-size: 18px;
}

.TpPlaceFont {
  font-weight: 600;
  font-size: 20px;
}

.processWeFollowContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.processWeFollowHeaderFont {
  font-weight: 600;
  font-size: 18px;
}

.videoFeedbackLink:hover {
  text-decoration: underline;
  color: #0090e5;
}

.removeLi li {
  list-style-type: none;
}

.removeLi li a {
  color: #181815 !important;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 16px;
}

.cfont1 {
  font-weight: 600;
  font-size: 36px;
}

.cbtn1 {
  background-color: #ee720b !important;
  border-radius: 10px !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  color: #fff !important;
}

.CurrentOpeningstitle {
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

.cbtn2 {
  background-color: #ee720b !important;
  border-radius: 47px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #fff !important;
}

.lifessfont {
  font-weight: 500;
  font-size: 28px;
  line-height: 45px;
}

.carreerLifont li span {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer !important;
}

.requirementUploadBox {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23203F59FF' stroke-width='3' stroke-dasharray='12' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
}

.requirementCustomInput {
  border: none !important;
  border-bottom: 1px solid #203f59 !important;
  border-radius: 0 !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}

.requirementCustomInput:focus {
  box-shadow: none !important;
}

.fileUploadPadding {
  padding-right: 8rem;
  padding-left: 8rem;
}

.cls03658C {
  color: #03658c;
}

.cls201E47 {
  color: #201e47;
}

.cls0E8BFF {
  color: #0e8bff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */

.modalXl .modal-body {
  background-color: #000000;
}

.col-half {
  flex: 0 0 auto;
  width: 8.33333333% !important;
}

.dFlexSpaceBtw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#myProgress {
  width: 100%;
  background-color: grey;
}

.myBar {
  width: 0%;
  /* height: 30px; */
  transition: width 6s linear;
}

.backgroundcol {
  background-color: #f7f7f7;
}

.diff {
  color: #0e2b3d;
  font-size: 32px;
  text-align: center;
}

.imagetimer {
  background-color: #fff0eb;
  margin-right: 35px;
}

.imagetimer2 {
  background-color: #fff0eb;
}

.fsize {
  font-size: 18px;
}

.btnclass .hoverBtnEffect {
  width: 14rem !important;
}

.btnclass .hoverBtnEffect {
  font-size: initial !important;
}

.imgstyle {
  height: 140px;
  width: 140px;
  left: 123px;
  top: 4928px;
  border-radius: 0px;
}

.imgfont {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.imgsubtext {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.what-clint-think .custom-blockquote {
  padding: 1em 1em 1em 3em !important;
}

.overview {
  padding-bottom: 60px !important;
}

.what-clint-padding {
  padding-bottom: 38px;
}

.gocoworq-detail .content-container {
  align-items: center;
  display: flex;
}

.width100 {
  width: 100%;
}

.minusmargin {
  margin-top: -24px;
}

.custom-blockquote2 {
  font-family: Georgia, serif;
  position: relative;
  margin: 0.5em;
  padding: 0.1em 2em 0.1em 0em;
}

.custom-blockquote2:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 3em;
  line-height: 1;
  top: -30px;
  left: 0px;
  content: "\201C";
}

.custom-blockquote2:after {
  font-family: Georgia, serif;
  position: absolute;
  float: right;
  font-size: 3em;
  line-height: 1;
  right: 232px;
  bottom: -55px;

  content: "\201D";
}

.outer-header-ui-ux {
  display: none;
}

.heading {
  color: #203f59;
  font-weight: 600;
  font-size: 35px;
  text-align: center;
}

.method-block-row {
  /* display: -webkit-box;
    display: -ms-flexbox; */
  display: flex;
  -webkit-box-pack: center;
  /* -ms-flex-pack: center; */
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px 16px;
}

.method-block-row .method-block-col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 33%;
  padding: 0 10px;
}

.method-block-row .method-block-col .method-content {
  margin-bottom: 80px;
}

.item-header {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.method-block-row .method-block-col .method-content h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 24px;
  text-decoration: none;
}

.method-block-row .method-block-col .method-content h3 a {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 24px;
  text-decoration: none;
}

.method-block-row .method-block-col .method-content .item-header .icon-wrapper {
  display: none;
}

.method-block-row
  .method-block-col
  .method-content
  .item-header
  .icon-wrapper
  img {
  height: 24px;
}

.method-block-row .method-block-col .method-content p {
  font-size: 16px;
  line-height: 24px;
}

.method-block-row .method-block-col .method-content a {
  display: block;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #12233d;
}

.method-block-row .method-block-col .method-content {
  margin-bottom: 80px;
}

.method-block-row .method-block-col .method-content a {
  display: block;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #12233d;
}

.method-block-row .method-block-col .method-content h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 24px;
  text-decoration: none;
}

.method-block-row .method-block-col .method-content h3 a {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 24px;
  text-decoration: none;
}

.method-block-row .method-block-col .method-content .item-header .icon-wrapper {
  display: none;
}

.method-block-row
  .method-block-col
  .method-content
  .item-header
  .icon-wrapper
  img {
  height: 24px;
}

.method-block-row .method-block-col .method-content p {
  font-size: 16px;
  line-height: 24px;
}

.method-block-row .method-block-col .method-content a {
  display: block;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #12233d;
}

.method-block-row .method-block-col .circle-block {
  position: relative;
  display: block;
  max-width: 100%;
  width: 350px;
  height: 350px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  margin-top: 1rem;
}

.method-block-row .method-block-col .circle-block .horizontal-line {
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #e5e5e5;
  top: calc(50% - 1px);
}

.method-block-row .method-block-col .circle-block .vertical-line {
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #e5e5e5;
  left: calc(50% - 1px);
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image-0 {
  position: absolute;
  padding-left: 95px;
  padding-top: 90px;
  padding-right: 53px;
  padding-bottom: 54px;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image
  img {
  /* -webkit-transform: scale(1);
  -ms-transform: scale(1); */
  transform: scale(1);
  /* -webkit-transition: .3s;
  -o-transition: .3s; */
  transition: 0.3s;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image-1 {
  position: absolute;
  right: 0;
  padding-left: 55px;
  padding-top: 90px;
  padding-right: 90px;
  padding-bottom: 55px;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image
  img {
  /* -webkit-transform: scale(1);
  -ms-transform: scale(1); */
  transform: scale(1);
  /* -webkit-transition: .3s;
  -o-transition: .3s; */
  transition: 0.3s;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image-2 {
  position: absolute;
  bottom: 0;
  padding-left: 90px;
  padding-top: 54px;
  padding-right: 46px;
  padding-bottom: 90px;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image
  img {
  /* -webkit-transform: scale(1);
  -ms-transform: scale(1); */
  transform: scale(1);
  /* -webkit-transition: .3s;
  -o-transition: .3s; */
  transition: 0.3s;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 57px;
  padding-top: 53px;
  padding-right: 90px;
  padding-bottom: 90px;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image
  img {
  /* -webkit-transform: scale(1);
  -ms-transform: scale(1); */
  transform: scale(1);
  /* -webkit-transition: .3s;
  -o-transition: .3s; */
  transition: 0.3s;
}

.method-block-row .method-block-col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 33%;
  padding: 0 10px;
}

.method-block-row .method-block-col .method-content {
  margin-bottom: 80px;
}

.item-header {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.method-block-row .method-block-col .method-content h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 24px;
  text-decoration: none;
}

.method-block-row .method-block-col .method-content h3 a {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 24px;
  text-decoration: none;
}

.method-block-row .method-block-col .method-content .item-header .icon-wrapper {
  display: none;
}

.method-block-row
  .method-block-col
  .method-content
  .item-header
  .icon-wrapper
  img {
  height: 24px;
}

.method-block-row .method-block-col .method-content p {
  font-size: 16px;
  line-height: 24px;
}

.method-block-row .method-block-col .method-content a {
  display: block;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #12233d;
}

.method-block-row .method-block-col .method-content {
  margin-bottom: 80px;
}

.item-header {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  font: inherit;
  vertical-align: baseline;
}

.h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.icon-wrapper {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image:hover
  img {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.circle-block {
  position: relative;
  display: block;
  max-width: 100% !important;
  width: 350px !important;
  height: 350px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  margin-top: 1rem !important;
}

.circle-block .horizontal-line {
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #e5e5e5;
  top: calc(50% - 1px);
}

.circle-block .vertical-line {
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #e5e5e5;
  left: calc(50% - 1px);
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image-0 {
  position: absolute;
  padding-left: 95px;
  padding-top: 90px;
  padding-right: 53px;
  padding-bottom: 54px;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image
  img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image-1 {
  position: absolute;
  right: 0;
  padding-left: 55px;
  padding-top: 90px;
  padding-right: 90px;
  padding-bottom: 55px;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image
  img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image-2 {
  position: absolute;
  bottom: 0;
  padding-left: 90px;
  padding-top: 54px;
  padding-right: 46px;
  padding-bottom: 90px;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image
  img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 57px;
  padding-top: 53px;
  padding-right: 90px;
  padding-bottom: 90px;
}

.method-block-row
  .method-block-col
  .circle-block
  a.our-methods--content-icon-image
  img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.textcolor {
  color: #203f59 !important;
}

@-webkit-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#example {
  background-color: #fff0eb;
  position: relative;
  height: 281px;
  width: 450px;
  margin: 0 auto;
}

#example img {
  position: absolute;
  left: 0;
}

#example img {
  -webkit-animation-name: cf4FadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 8s;

  -moz-animation-name: cf4FadeInOut;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 8s;

  -o-animation-name: cf4FadeInOut;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 8s;

  animation-name: cf4FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
}

#example img:nth-of-type(1) {
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  animation-delay: 6s;
}

#example img:nth-of-type(2) {
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
}

#example img:nth-of-type(3) {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}

#example img:nth-of-type(4) {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}

.fade-in-image {
  animation: fadeIn 4s !important;
  -webkit-animation: fadeIn 4s !important;
  -moz-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
}

.fade-in-image2 {
  animation: fadeIn2 2s !important;
  -webkit-animation: fadeIn2 4s !important;
  -moz-animation: fadeIn2 4s;
  -o-animation: fadeIn2 4s;
  -ms-animation: fadeIn2 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fw-600 {
  font-weight: 600 !important;
}

.aboutUsIndImage.SetImage {
  width: 100% !important;
  object-fit: fill !important;
  height: 220px !important;
}

.boldtext {
  font-weight: bold;
}
.ImgHeight3 {
  height: 3em !important;
}
.letterSpacing2px {
  letter-spacing: 0.2px;
}

.text-justify {
  text-align: justify;
  word-spacing: 0em;
}
@media only screen and (min-width: 767px) {
  .gridClass {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 0fr));
    /* grid-gap: 1rem; */
  }
}
.gridClass2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 0fr));
}

@media only screen and (max-width: 600px) {
  .gridClass {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
.RemovePadding {
  padding-top: 0px !important;
}
.addHeight {
  min-height: 221px;
}
.Setheight {
  padding-top: 62px;
}
.SetDivImage .lazy-load-image-loaded {
  display: flex !important;
  justify-content: center !important;
}

.heroImgCss {
  width: 100%;
  max-height: 500px;
  object-fit: fill;
  height: 500px;
}

.careercardbox {
  padding-left: 0.8em;
  font-weight: 600;
}
.careerkeyPoints > li > h6 {
  color: #606066;
  font-size: 1.2rem;
  text-align: left;
}
.careerkeyPoints li::before {
  color: #606066;
}
@media only screen and (max-width: 600px) {
  .px-7 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .IS_Container {
    height: 100% !important;
  }
}
.BlueClass {
  color: #203f59;
}

@media only screen and (max-width: 600px) {
  .getToKnowCardContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .getToKnowCard {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .textalignleft,
  .aufont2 {
    text-align: justify !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    hyphens: auto;
    -webkit-hyphens: auto !important;
    word-spacing: -0.02em;
  }
  .BlueClass {
    text-align: center;
  }
  .fs-15 {
    text-align: justify;
    padding-left: 1.3rem !important;
    padding-right: 1.4rem !important;
    hyphens: auto;
    -webkit-hyphens: auto !important;
    word-spacing: -0.02em;
  }
  .socialLogo1 {
    padding-left: 0rem !important;
    padding-right: 0px !important;
  }

  .socialLogo2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .socialLogo3 {
    padding-left: 0px !important;
    padding-right: 2.7rem !important;
  }
  .heroImgCss {
    height: auto;
  }
  .majorBenefitHeading {
    font-size: 24px !important;
    line-height: 34px;
  }

  .devTags h1 {
    font-size: 24px !important;
    line-height: 34px;
  }
  .ourKeyHeading {
    font-size: 22px !important;
    line-height: 34px;
  }
  .ourKeyHeadingApp {
    font-size: 20px !important;
    line-height: 34px;
    margin-bottom: 0px;
  }
  .TpPlaceFont,
  .weBestSubHeading {
    font-size: 15px;
  }
  .ourKeyCardtext {
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto !important;
    word-spacing: -0.02em;
  }

  .NamePlateMob {
    flex-direction: column-reverse;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  .NamePlateSize {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .NamePlateImg {
    text-align: center !important;
    /* padding-top: 5px !important; */
    padding-bottom: 3px !important;
    vertical-align: inherit !important;
  }
  .NamePlateCardHeading {
    padding-top: 16px !important;
    /* padding-bottom: 16px !important; */
    font-size: 14px !important;
    text-align: center !important;
  }
  .technologiesHeading,
  .webProcessHeading {
    font-size: 24px !important;
    line-height: 34px;
  }
  .commonClassSliderHdn {
    padding-bottom: 25px;
  }
  .RecentWorkHeading {
    font-size: 23px !important;
    line-height: 34px;
  }
  .recentWorkbox1 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .whyChooseUsHeadingApp {
    font-size: 21px !important;
    line-height: 34px;
  }

  .ourKeyCardSize,
  .whyChooseContentCss {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .whyChooseContentCss {
    margin: 0px !important;
  }
  .overviewHeading {
    font-size: 24px !important;
    margin-bottom: 0px !important;
    line-height: 35px !important;
  }
  .weFollow_pdng {
    padding-top: 1.2rem;
  }
  .cta1_mob {
    font-size: 16px;
    line-height: 24px;
  }
  .techmobilebox {
    display: flex;
    font-size: 13px;
    font-weight: 600;
    gap: 1rem;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .techHover:hover {
    cursor: pointer;
    /* width: 100%; */
    border-radius: 5px;
    /* padding: 5px; */
    background-color: #e0e0e0 !important;
  }
  .whChooseUseBody {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: 0px !important;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto !important;
    word-spacing: -0.02em;
    line-height: 24px;
  }
  .whchmob {
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
    /* margin-left: 0px !important; */
    padding-left: 0px !important;
  }
  .whchIconCss {
    padding-left: 0px !important;
    margin-bottom: 10px;
  }
  .whChooseUseHeader {
    font-size: 17px !important;
    line-height: 20px;
  }
  .recentBox {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .technologySubHeader {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
  }

  .personalizedtitle1App {
    font-size: 12px !important;
    line-height: 24px !important;
  }
  .webProcessHeadingApp {
    font-size: 22px !important;
    line-height: 34px;
  }
  .subRecentHeadingMob {
    font-size: 16px !important;
  }
  .recentsubhd {
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
  .recenthdpd {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-size: 26px !important;
  }
  .waysSubhd {
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: 16px !important;
    line-height: 21px;
    font-weight: 500 !important;
  }
  .herocontainertext {
    text-align: justify;
    hyphens: auto;
    word-spacing: -0.02em;
  }
  .personalizedtitle1_UIUX {
    font-size: 11.4px !important;
    line-height: 24px !important;
  }
  .technologiesHeadingUIUX {
    font-size: 26px !important;
    line-height: 34px;
    /* padding-left: 0.7rem !important;
    padding-right: 0.rem !important; */
  }
  .technologySubHeaderUIUX {
    font-size: 16px !important;
    font-weight: 500;
  }
  .imgdnone {
    display: none;
  }
  .px-8 {
    padding-right: 4.8rem rem !important;
    padding-left: 4rem !important;
  }
  .seoProcessHeadingApp {
    font-size: 24px !important;
    line-height: 34px;
  }
  .whyChooseUsHeadingSeo {
    font-size: 24px !important;
    line-height: 34px;
  }
  .devopsServiceheadingCss {
    font-size: 24px !important;
    line-height: 38px;
  }

  .ourKeyHeadingDevops {
    font-size: 25px !important;
    line-height: 34px;
    margin-bottom: 0px;
  }
  .devopsTechMob {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 600;
    font-size: 27px !important;
    line-height: 39px;
  }
  .ProjectTechMob {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 600;
    font-size: 24px !important;
    line-height: 34px;
  }
  .devopsTechMobsubHeading {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    padding-top: 0px !important;
  }

  .NewServicePara.SetMobileHeader {
    margin-top: 0px !important;
  }
  .SetMobileHeader h3 {
    font-size: 18px !important;
    text-align: start !important;
  }
  .cloudSetupMob {
    padding-top: 0.9rem !important;
  }
  .devopsRemoveMArginMob .ServicePara {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    hyphens: auto;
    word-spacing: -0.02em;
  }
  .DevopsServicebgClr {
    background: #fff;
  }
  .fontSizeEighteen {
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto !important;
    word-spacing: -0.02em;
  }
  .herotextJustify {
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto !important;
    word-spacing: -0.02em;
  }
  .recentportfolioheading {
    font-size: 24px !important;
    line-height: 34px !important;
    color: #203f59;
  }
  .recentPPCards {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
    margin-bottom: 1rem;
  }
  .newportpadding {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
    padding-left: 1.7rem !important;
  }
  .setHeightMob {
    padding-top: 0.3rem !important;
  }

  .porttechHeading {
    font-size: 24px !important;
    line-height: 34px !important;
  }
  .porttechSubHeading {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
  }
  .careerImg2 {
    height: auto !important;
  }
  .careerVwBtn {
    margin: auto;
  }
  .grtPlcHeadingBox {
    margin-top: 2rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .grtPlcHeading {
    font-size: 24px !important;
    line-height: 34px;
  }
  .grtPlcTxt {
    hyphens: auto;
    word-spacing: -0.02em;
  }
  .padMob {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .positionFont {
    font-size: 20px !important;
    /* line-height: 34px; */
  }
  .dataText {
    text-align: justify;
    hyphens: auto;
    word-spacing: -0.2em;
  }
  .marginRemove {
    margin-bottom: 0px !important;
  }
  .contactAdd {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .fileTxt {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .fntSize {
    font-size: 13px;
    text-align: center;
  }
  .txtcntr {
    text-align: center;
  }
  .listFntSize {
    font-size: 15px !important;
  }
  .remMarPad {
    padding-right: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
  }
  .remPad {
    padding-right: 0px !important;
  }
  .NwfontColorBlue {
    color: #30303c;
  }
  .C_phpSpace {
    display: flex;
    align-items: center;
  }

  .pl_left2 {
    padding-left: 10px;
  }
  .pleft0 {
    padding-left: 0px !important;
  }
  .pr_Right {
    padding-right: 0 !important;
  }
  .whchSSHeading {
    font-size: 25px !important;
    line-height: 38px !important;
  }
  .marginMinus {
    margin-left: -15px;
  }
  .accordions-button {
    font-weight: 500;
    font-size: 16px !important;
  }
  .accordion-body {
    hyphens: auto;
    -webkit-hyphens: auto;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-align: justify;
    word-spacing: -0.02em;
  }
}

.accordions-button:focus {
  z-index: 3;
  border-color: none !important;
  box-shadow: none !important;
}

.accordions-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-weight: 600;
  font-size: 21px;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: transparent !important;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
/* .accordions-button h4 {
  font-weight: 600 !important;
  font-size: 21px !important;
} */
.accordion-item {
  color: none;
  background-color: #f7f7f7 !important;
  border: none !important;
  border-radius: 10px !important;
}

.accordions-button::after {
  background-image: url(/public/images/plus.png) !important;
  transition: none !important;
}
.accordions-button:not(.collapsed)::after {
  background-image: url(/public/images/minus.png) !important;
  transition: none !important;
}
.accordions-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
}
.accordions-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordions-button:not(.collapsed) {
  color: #ee720b !important;
  box-shadow: none !important;
}

.divHoverEffect:hover .fontColorBlue h3 {
  color: #ee720b;
  /* transition: 0.8s; */
}
.divHoverEffect:hover {
  /* border-top: #a4b2b9 solid 0.1px;
  border-bottom: #a4b2b9 solid 0.1px; */
  filter: drop-shadow(0px 2px 20px #b4bec4);
  transition: 0.8s;
  transform: translateY(-3px);
}

.workWithUsContainer {
  background: #f7f7f7;
  border-radius: 10px;
  min-height: 100%;
}
.workWithUsContainer:hover {
  filter: drop-shadow(0px 2px 20px #b4bec4);
  transform: translateY(-10px);
  transition: 0.8s;
}
.BradCrumbContainer:hover {
  filter: drop-shadow(0px 2px 20px rgba(238, 114, 11, 0.3));
  transition: 0.8s;
  border: transparent;
  /* border: 0.5px solid white; */
}
.MajorBenefitsContainer {
  border: 0.5px solid rgba(116, 116, 116, 0.25);
  border-radius: 15px;
  box-shadow: rgba(163, 163, 163, 0.2) 0px 7px 29px 0px;
}
.RecentContainer:hover {
  filter: drop-shadow(0px 2px 20px #b4bec4);
  transition: 0.8s;
}
.processCircle {
  background: #203f59;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.processCenter {
  display: flex;
  justify-content: center;
}

.verticalCircle {
  background: #203f59;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.verticalBottom {
  border-left: 5px solid #203f59;
  height: 38px;
  position: absolute;
  top: -65px;
}
.verticalCircleBottom {
  background: #203f59;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: absolute;
  top: -87px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
hr {
  margin: 0 !important;
  color: #7e97a3 !important;
  border: 0;
  border-top: 12px solid !important;
  opacity: unset !important;
  border-radius: 10px;
}

.HeroSectionHover {
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.dropdown-item:focus {
  background-color: transparent !important;
}

.processDynamicDiv {
  background: rgb(255, 255, 255);
  border-radius: 40px;
  min-height: 100%;
}
.processDynamicDiv:hover {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.08);
}

.pageNotFoundFont1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #203f59;
}
.pageNotFoundFont2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #203f59;
}
.Port_Bg {
  background-image: url(../../public/images/Portfolio-bg.png);
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: 0.9;
}
.pl-5 {
  padding-left: 5% !important;
}
.port-hero-text {
  font-size: 32px;
  color: white;
  font-weight: 600;
}
.port-hero-text1 {
  font-size: 32px;
  color: #fcd47e;
  font-weight: 600;
}
.port-hero-text2 {
  font-size: 20px;
  color: white;
  font-weight: 600;
}
.port-hero-text3 {
  font-size: 20px;
  color: #fcd47e;
  font-weight: 400;
}
.port-img-text {
  font-size: 24px;
  color: white;
  background-color: black;
  text-decoration: none;
  width: 214px;
  height: 60px;
  text-align: center;
  line-height: 2;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  bottom: 0%;
  left: 13px;
  font-weight: 600;
}
.port-img-text1 {
  font-size: 24px;
  color: white;
  background-color: black;
  text-decoration: none;
  width: 214px;
  height: 60px;
  text-align: center;
  line-height: 2;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  bottom: 0%;
  left: 14px;
  font-weight: 600;
}
.port-img-text-anc {
  position: relative;
}
.port-img-text-anc img {
  display: block;
  min-width: 70px;
  margin: 0 1vw !important;
}

.tech-txt1 {
  color: #2d4be8;
}
.wid-7 {
  flex: 0 0 auto;
  width: 9%;
}
.wid-9 {
  flex: 0 0 auto;
  width: 11%;
}
.wid-11 {
  width: 11%;
}
.text-center1 {
  text-align: -webkit-center;
}
.row-new-logo {
  justify-content: center;
  align-items: center;
}
.bor-card {
  border: 1px solid #f2eddc;
  border-radius: 10px;
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 20px;
}
.bor-card1 {
  border: 1px solid #f2eddc;
  border-radius: 10px;
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 20px;
}
.hero-card-txt {
  font-size: 15px;
  line-height: 24px;
  text-align: justify;
  color: #606066;
}
.hero-card-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #0e2b3d;
  text-transform: capitalize;
}
.hero-card-heading10 {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #0e2b3d;
  text-transform: capitalize;
}
.headerHeading {
  font-size: 30px;
  font-weight: 600;
}
.pb1 {
  padding-bottom: 1px;
}
.hero-card-heading1 {
  color: #0e2b3d;
  font-size: 24px;
  line-height: 36px;
  background-repeat: no-repeat;
  text-transform: capitalize;
}

.hover-hero-card {
  transition: box-shadow 0.5s; /* Add a smooth transition effect for the box shadow */
}

.hover-hero-card-aboutus {
  transition: box-shadow 0.5s; /* Add a smooth transition effect for the box shadow */
  height: 100%;
}
.pos-img {
  right: 7%;
}
.maxwid-450 {
  max-width: 450px;
}

.hover-hero-card:hover {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
}

.bg-freecon {
  background-image: url("../../public/images/bg-plane.svg");
  height: 255px;
  color: #ffffff;
  font-weight: 700;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
  padding-top: 45px;
}
.btn-plane {
  height: 46.91px;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #ee720b;
  background: #ffffff;
  border-radius: 14px;
  border: none;
  padding: 20px;
  line-height: 0;
  margin-top: 30px;
}
.font30 {
  font-size: 30px;
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}
.mx5 {
  margin-left: 5% !important;
  margin-right: 5% !important;
  border-radius: 10px;
}
.mx5:hover,
.mx5:hover {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
}

.pb50 {
  padding-bottom: 50px;
}
.Card-ul {
  padding-left: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #203f59;
  margin-left: 30px;
}
.Card-ul-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}
.titleSpan {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #2d4be8;
}
.descSpan {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #203f59;
}

.default-header-card {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #2d4be8;
}
.pb70 {
  padding-bottom: 70px;
}
.default-Paraheader-card {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #0e2b3d;
}
.custom-blockquote3 {
  font-family: Georgia, serif;
  position: relative;
  padding: 1.1em 4em 1.1em 4em;
}

.custom-blockquote3:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 3em;
  line-height: 1;
  top: 0;
  left: 0;
  content: "\201C";
}
.custom-blockquote3:after {
  font-family: Georgia, serif;
  position: absolute;
  float: right;
  font-size: 3em;
  line-height: 1;
  right: 0;
  bottom: -0.5em;
  content: "\201D";
}
.custom-blockquote3 footer {
  padding: 0 2em 0 0;
  text-align: right;
}
.custom-blockquote3 cite:before {
  content: "\2013";
}
.custom-blockquote3 h6 {
  line-height: 30px;
}

.QuotePara {
  display: flex;
  align-items: center;
  text-align: center;
  color: #606066;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
}
.paraName {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #203f59;
}
.paraPost {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #203f59;
}
.spanPara {
  margin-top: 40px !important;
  margin-bottom: 70px !important;
}

.rowType {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  align-items: center;
}
.hoverEffect {
  animation: 1s;
}

.wid-10 {
  width: 10%;
}

.overviewheader {
  font-weight: 600;
  font-size: 36px;
  letter-spacing: 0.5px;
  color: #203f59;
}
.overviewPara {
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  text-align: justify;
  color: #606066;
}

.postgre-hover {
  color: #336791;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.Dotted-bg {
  background-image: url("/public/images/Dotted-bg.svg");
  background-repeat: no-repeat;
}
.headerColorAndSize {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #0e2b3d;
  text-transform: capitalize;
}
.listColorAndSize {
  font-weight: 400;
  font-size: 15px;
  color: #203f59;
  text-align: justify;
}
.divColorAndSize {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #203f59;
}
.labelColorAndSize {
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #2d4be8;
}
.maxwid-55 {
  max-width: 55% !important;
}
.divider {
  color: #f2eddc;
  border: 1px solid #f2eddc;
  border-top: 0 !important;
  opacity: unset !important;
  border-radius: 0;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.customheader {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #2d4be8;
}
.border10 {
  border-radius: 10px;
  border: 1px solid #f2eddc;
}
.k12Header {
  font-weight: 400;
  font-size: 32px;
  color: #151821;
}
.k12Label {
  font-weight: 600;
  color: #3cbba5;
  font-size: 48px;
}

.k12Body {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #203f59;
}
.k12para {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justify;
  color: #606066;
}
.k12text {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #203f59;
}
.k12textColor {
  color: #3cbba5;
}
.techLogo {
  width: 1319px !important;
  height: 125px;
  background-color: #f3f3f3;
  border-radius: 10px;
}
.wid-15 {
  width: 15%;
}
.headerk12 {
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  color: #293a5c;
}
.k12paraColor {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #606066;
}
.lineHeader {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #3cbba5;
}
.sideSection {
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #203f59;
}
.sideSectionlabel {
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #3cbba5;
}
.sideSectionPara {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: justify;
  color: #606066;
}
.sideSectionParaLabel {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #606066;
}
.pb150 {
  margin-bottom: 150px;
}
.sideText {
  font-weight: 600;
  font-size: 56px;
  color: #3cbba5;
  position: relative;
  z-index: 1;
}
.backPanel {
  font-size: 75px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #fff;
  position: absolute;
  top: 0px;
}
.backPanel1 {
  font-size: 75px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #fff;
  position: absolute;
  top: 0px;
}
.sideText1 {
  font-weight: 600;
  font-size: 56px;
  color: #3cbba5;
  position: relative;
  z-index: 1;
}
.sideText2 {
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  color: #3cbba5;
  background-image: url("../../public/images/School_Panel.svg");
  background-repeat: no-repeat;
}
.sideText3 {
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  color: #3cbba5;
  background-image: url("../../public/images/Technician_Panel.svg");
  background-repeat: no-repeat;
}

.sideimage {
  position: absolute;
  top: -32%;
}
.sideimage1 {
  position: absolute;
  top: -24%;
}
.leftSideImage {
  position: absolute;
  top: -150%;
}
.leftSideImage1 {
  position: absolute;
  top: -85%;
}

.bgBlack {
  background-color: #151821;
}
.bgBlackHeader {
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
}
.bgBlackPara {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}
.arrow1 {
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 40px;
  animation: moveArrow 8s linear infinite;
}

.imgClass {
  background: white;
  z-index: 1;
  position: relative;
  border-radius: 3px;
  padding: 3px;
}

@keyframes moveArrow {
  0% {
    left: 13%;
  }

  100% {
    left: 87%;
  }
}
.imageHeader {
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}
.imagePara {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.lines {
  border: 1px dashed white;
  height: 0px;
  width: 75% !important;
  position: absolute;
  left: 12%;
  top: 45%;
}
.top-80 {
  top: -50px;
  z-index: 2;
}
.carousel img {
  height: fit-content !important;
  width: fit-content !important;
}
.blurFont {
  font-size: 100px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #f7f7f7;
  position: absolute;
  top: 20px;
  left: 62px;
  z-index: -1;
}
.k12HeaderSide {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #293a5c;
}
.k12HeaderSideLabel {
  color: #3cbba5;
}
.sprigK12Para {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justify;
  color: #606066;
}
.sprigK12ParaLabel {
  font-size: 16px;
  font-weight: 600;
}
.borderk1210 {
  border-radius: 10px;
}
@media (min-width: 1280px) and (max-width: 1455px) {
  .maxwid-450 {
    max-width: 500px;
  }
}
@media (min-width: 767px) and (max-width: 1500px) {
  .min-width644 {
    min-width: none !important;
  }
  .backPanel {
    font-size: 55px !important;
    top: 10px !important;
  }
  .backPanel1 {
    font-size: 55px !important;
    top: 10px !important;
  }
  .sideimage {
    top: -25%;
  }
  .sideimage1 {
    top: -20%;
  }
  .leftSideImage1 {
    top: -65%;
  }
}
.divHoverImgContainer {
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}
.divHoverContainer {
  border-radius: 8px 8px 0px 0px;
  /* box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15); */
  background: var(--box-bg-color, #f7f7f7);
}
.divHoverContainer1 {
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
  background: var(--box-bg-color, #f7f7f7);
}
.col1 {
  flex: 0 0 auto !important;
  width: 7.333333% !important;
}
.col2 {
  flex: 0 0 auto !important;
  width: 3.333333% !important;
}
.hover-bgBlue {
  background: linear-gradient(to right, #203f59 50%, white 50%);
  background-size: 200% 101%;
  background-position: right bottom;
  transition: none;
}
.hover-bgBlue h3 {
  transition: none;
}
.hover-bgBlue:hover {
  background-position: left bottom;
  color: white;
}
.hover-bgBlue:hover h3 {
  color: white;
}
.zoom-in-out-box {
  animation: zoom-in-zoom-out 10s ease infinite;
}
.fade-in-out-box {
  animation: fade-in-fade-out 10s linear infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
}
.max70 {
  max-width: 70% !important;
}
.max60 {
  max-width: 60% !important;
}
.max80 {
  max-width: 80% !important;
}
@keyframes fade-in-fade-out {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes w1anim {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
}
.w1 {
  animation: w1anim 40s infinite;
}

.word {
  opacity: 0;
}
.min-width644 {
  min-width: 644px;
}
.logo-height {
  height: 83px;
  width: 70px;
}
.logo-height1 {
  height: 83px;
  width: 90px;
}
@media (min-width: 601px) and (max-width: 767px) {
  .PortFoliocard {
    padding: 25px !important;
  }
}
@media only screen and (max-width: 600px) {
  .PortFoliocard {
    padding: 25px 34px !important;
  }
}
.PortFoliocard {
  box-shadow: 0px 0px 20px rgb(0 0 0 / 5%) !important;
  border: none !important;
  background-color: white;
  padding: 190px 80px 174px 90px;
  margin: 0 auto;
  min-height: 100%;
}

.A2Header h1 {
  font-size: 32px;
  color: #1b3568;
  font-style: normal;
  font-weight: 300;
}
.A2Header label {
  font-size: 48px;
  color: #1b3568;
  font-weight: 600;
}
.a2Hero label {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #1b3568;
}
.a2Hero span {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #606066;
}
.border20 {
  border-radius: 20px;
}
.a2content {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #1b3568;
}
.a2content label {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0em;
}
.a2overview h2 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #1b3568;
}
.a2overview p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: justify;
  color: #606066;
}
.a2overviewText {
  font-size: 100px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #f7f7f7;
  position: absolute;
  top: -20px;
  z-index: -1;
}
.a2overviewText1 {
  font-size: 100px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #f7f7f7;
  position: absolute;
  top: -20px;
  z-index: -1;
}
.a2lineheader {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #1b3568;
}
.crmsection p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: #606066;
}
.hoverBorder {
  border: 1px solid #f1f2f3;
  border-radius: 4px;
}
.hoverBorder:hover {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
}
.a2Features h2 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #1b3568;
}
.a2FeaturesText {
  font-size: 100px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #f7f7f7;
  position: absolute;
  top: 0px;
  z-index: -1;
  left: 0;
  top: -20px;
}
.a2mainsection h3 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #1b3568;
}
.a2mainsection p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: justify;
  color: #606066;
}
.sidesectionimage1 {
  font-size: 100px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #f7f7f7;
  position: absolute;
  top: -15px;
  z-index: -1;
}
.sidesectionimage2 {
  font-size: 85px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #f7f7f7;
  position: absolute;
  top: -15px;
  z-index: -1;
}
.btn-a2 {
  border-radius: 52px;
  border: none;
  background-color: #1b3568;
  color: white;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.bg-a2 {
  background-color: #eff0f6;
  border-radius: 37px;
  box-shadow: 0px 6.536630153656006px 22.411304473876953px 0px #14142b0d;
}
.a2fontbg h2 {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #1b3568;
}
.a2fontbg p {
  font-size: 16px;
  font-weight: 400;
  color: #6f6c90;
}
.mobileframe {
  position: absolute;
  top: 97px;
  left: 0;
}
.op25 {
  opacity: 0.25;
}
.vertical {
  display: inline-block;
  width: 20%;
  height: 40px;
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  transform: rotate(90deg);
}
.vertical {
  padding: 0 !important;
  top: -15px;
  position: absolute;
  -webkit-transform-origin-x: left;
  left: -18px;
  box-shadow: none !important;
}
.progress-bar {
  box-shadow: none;
}
.progress {
  background-color: #f7f7f7 !important;
  border-radius: 0px !important;
}
.progress-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.progress-bar {
  position: relative;
  width: 1px !important;
  height: 587px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.progress {
  background-color: #1b3568;
  transition: height 0.2s;
}

.dot {
  position: absolute;
  top: -3%;
  left: 13px;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #1b3568;
  transition: top 0.2s;
}
.mlr {
  margin-right: 6%;
  margin-left: 1%;
}
.a2mainsection img {
  width: 527px;
  height: 416px;
}
.A2Carousel img {
  max-width: 700px !important;
  max-height: 500px !important;
}
@media screen and (max-width: 767px) {
  .a2mainsection img {
    width: 329px;
    height: 259px;
  }
  .A2Header {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    text-align: center;
  }
  .A2Header h1 {
    font-size: 20px !important;
  }
  .A2mobileCarousel {
    height: 415px;
  }
  .A2Header label {
    font-size: 28px !important;
    font-weight: 600 !important;
  }
  .A2Header p {
    font-size: 14px !important;
    font-weight: 400;
    color: #606066;
  }
  .A2Header h5 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .a2content label {
    font-size: 30px !important;
  }
  .a2content {
    font-size: 14px !important;
  }
  .mlr {
    margin: 0 auto !important;
  }
  .a2overview h2 {
    font-size: 28px !important;
  }
  .a2overviewText {
    font-size: 60px;
    top: -10px;
    right: 80px;
  }
  .a2overviewText1 {
    font-size: 60px;
    top: -10px;
    right: 60px;
  }
  .a2lineheader {
    font-size: 18px !important;
  }
  .crmsection p {
    font-size: 11px;
  }
  .a2FeaturesText {
    font-size: 60px;
  }
  .a2Features h2 {
    font-size: 28px;
  }
  .a2mainsection h3 {
    font-size: 28px;
  }
  .sidesectionimage1 {
    font-size: 60px;
    top: -10px;
    left: 60px;
  }
  .sidesectionimage3 {
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #f7f7f7;
    position: absolute;
    top: 0px;
    z-index: -1;
    left: 120px;
  }
  .sidesectionimage4 {
    font-size: 60px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #f7f7f7;
    position: absolute;
    top: -15px;
    z-index: -1;
    left: 120px;
  }
  .bg-a2 {
    color: #1b3568;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 0px;
    box-shadow: none;
  }
  .btn-a2 {
    border-radius: 10px;
  }
  .mobileframe {
    top: 48px;
  }
}
.bgF3F3F3 {
  background-color: #f3f3f3;
}
.d-content {
  display: contents !important;
}
.a2divider {
  margin-top: 1.5rem !important;
  border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.react-tel-input .form-control.ContactPhone {
  width: 100%;
  height: 74.46px;
}
.ApiHeroSection h1 {
  color: #203f59;
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
}
.ApiHeroSection p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justify;
}

.auContainer.auContainer1 {
  background-color: #f7f7f7 !important;
  border-radius: 16px;
}
.fontBlue {
  color: #203f59;
}
/* .ApiHeroSection button {
  width: 225px;
  height: 47px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  color: #fff;
  border: none;
  background-color: #203f59;
} */
.btn-ApiQuote {
  border-radius: 10px;
  background-color: #ee720b;
  color: white;
  border: none;
  padding: 1rem;
}
.ApiQuote {
  background-image: url("/public/images/ApiIntegrationQuoteBg.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-radius: 30px;
}
.ApiQuoteHeader {
  font-size: 30px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
.card.ApiCard {
  min-height: 301px;
  height: 100%;
  width: 100%;
  background: #f7f7f7;
  border: none;
  display: flex;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  transition: all 0.7s ease-in-out;
  overflow: hidden;
}
.ApiCard .c-txt-header {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #203f59;
  transition: all 0.7s ease-in-out;
}
.ApiCard .c-txt {
  color: #606066;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  transition: all 0.7s ease-in-out;
}
.card.ApiCard::before {
  content: "";
  position: absolute;
  height: 30%;
  width: 100%;
  background-color: #203f59;
  bottom: 0;
  right: 0;
  transform: translatey(70px);
  transition: all 0.7s ease-in-out;
}

.ApiCard .c-txt,
.ApiCard .c-txt-header {
  z-index: 2;
}

.ApiCard.card:hover::before {
  transform: scale(7) translate(-20px);
}

.ApiCard.card:hover .c-txt-header {
  color: #fff;
}
.ApiCard.card:hover .c-txt {
  color: #f3f3f3;
}
.Api-Title {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0em;
}
.card-title {
  color: #203f59;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0em;
}

.small-desc {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #606066;
}

.small-desc {
  font-size: 1em;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: #203f59;
  border-radius: 0 0 0 15px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.go-card {
  display: block;
  position: relative;
  width: 281px;
  height: 335px;
  background-color: #f2f8f9;
  border-radius: 15px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: #f7f7f7;
}

.go-card:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #203f59;
  height: 32px;
  width: 32px;
  border-radius: 15px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s ease-out;
}

.go-card:hover:before {
  transform: scale(28);
}

.go-card:hover .small-desc {
  transition: all 0.7s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.go-card:hover .card-title {
  transition: all 0.7s ease-out;
  color: #ffffff;
}
.go-card:hover .go-corner,
.go-card:hover .go-arrow {
  color: #203f59;
  background-color: #ffffff;
}
.verticalLineTop {
  border-left: 5px solid #203f59;
  height: 38px;
  position: absolute;
}
.col-20 {
  flex: 0 0 auto;
  width: 20% !important;
}
.ApiApps {
  background-color: #f3f3f3;
  border-radius: 15px;
  padding: 1rem;
  height: 80px;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ApiService {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background-color: white;
  align-items: center;
  display: flex;
}
.ApiService:hover {
  box-shadow: 0px 2px 20px 0px #00000026;
}
.ApiServiceHeader {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #203f59 !important;
}
.ApiService p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justified;
  color: #606066;
}
.ApiContent {
  border-radius: 15px;
  border: 0.5px solid #0000001a;
  min-height: 150px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.ApiContent:hover {
  box-shadow: 0px 0px 15px 0px #0000001a;
}
.ApiContent h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #ee720b;
}
.ApiContent p {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #606066;
  margin: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.ApiApps:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.md-30 {
  margin-right: 30px;
}
.IndianaHeader h1 {
  color: #fff;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
}
.IndianaHeader p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.bg-262626 {
  background-color: #262626;
}
.IndianaQuote {
  color: #2b2b2b;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.IndianaForm {
  width: 110%;
  flex-shrink: 0;
  border-radius: 10px;
}
.ProjectsCount {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
  min-width: 85% !important;
  max-height: 150px !important;
  max-width: 95%;
  min-height: 120px;
}
.CountProject {
  color: #203f59;
  font-size: 48px;
  font-weight: 600;
}
.ProjectTitle {
  color: #203f59;
  font-size: 16px;
  font-weight: 500;
}
.bor-right-000 {
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}
.IndianaHr {
  border-top: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.top-60 {
  top: -60px !important;
}
.IndianaTable {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #203f59;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg66575333 {
  background-color: #66575333;
  border-radius: 48px;
}
.bgF7913033 {
  background-color: #f7913033;
  border-radius: 48px;
}
.bg55A67E33 {
  background: #55a67e33;
  border-radius: 48px;
}
.bg77BFDD33 {
  background: #77bfdd33;
  border-radius: 48px;
}
.bgFD7A7C33 {
  background: #fd7a7c33;
  border-radius: 48px;
}
.IndianaImgHeight {
  max-height: 331px;
  max-width: 525px;
}
.bor-48 {
  border-radius: 48px;
  border: 1px solid #d8d8d8;
}
.IndianaWhyChoose {
  border-radius: 20px;
  background: #ee720b0d;
  height: 100%;
}
.WhyChooseIndiana {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #203f59;
}
.WhyChooseIndiana p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
}
.IndianaTpPlaceFont {
  color: #ee720b !important;
}
.IndianaTpText {
  color: #203f59;
}
.Indiana.recentWorkHeader {
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .IndianaHeader h1 {
    font-size: 22px !important;
    line-height: 30px !important;
  }
  .IndianaHeader p {
    font-size: 16px !important;
    text-align: justify;
    word-spacing: -2px;
  }
  .IndianaForm {
    height: 100%;
    width: 100%;
  }
  .ProjectsCount {
    height: 100%;
    width: 100%;
  }
  .bg66575333,
  .bgFD7A7C33,
  .bg77BFDD33,
  .bgF7913033,
  .bg66575333,
  .bg55A67E33 {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .IndianaContent p {
    font-size: 16px !important;
  }
  .IndianaVideo {
    min-height: 200px;
  }
  .Indiana.recentWorkHeader {
    font-size: 16px;
    padding-top: 0.5rem;
  }
  .recentWorkBody {
    font-size: 12px;
  }
  .ProjectTitle {
    font-size: 15px;
  }
  .md-30 {
    margin-right: 0px;
  }
  .col-20 {
    width: 50% !important;
  }
  .px-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .ApiHeroSection h1 {
    font-size: 22px !important;
    line-height: 30px !important;
  }
  .ApiQuoteHeader {
    line-height: 25px;
    text-align: center;
    font-size: 15px;
  }
  .ApiHeroSection p {
    text-align: justify;
  }
  .auContainer.auContainer1 {
    border-radius: 0 !important;
  }
  .Api-Title {
    font-size: 20px;
  }
  .card.ApiCard::before {
    height: 25%;
  }
  .card.ApiCard {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 401px;
  }
  .ApiQuote {
    flex-direction: column-reverse;
    background-position: top !important;
    background-size: cover !important;
  }
  .crmQuote {
    flex-direction: column-reverse;
  }
  .erpQuote {
    flex-direction: column-reverse;
  }
  .ecommerceQuote {
    flex-direction: column-reverse;
  }
  .webappQuote {
    flex-direction: column-reverse;
  }
  .ApiQuote h2 {
    font-size: 20px;
    line-height: 25px;
  }
  .wid-fill {
    height: 250px !important;
  }
  .Blog-header {
    width: 90% !important;
  }
  .topright-7020 {
    left: 6% !important;
    top: 95% !important;
  }
  .authorImage {
    height: 35px !important;
  }
  .authorBlog a {
    font-size: 14px !important;
  }
  .contentImage {
    height: 200px !important;
  }
  .Blog-header p {
    font-size: 16px !important;
  }
  .HeroDate {
    font-size: 12px !important;
  }
  .anchorOrange {
    font-size: 16px !important ;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .postHeaders {
    font-size: 26px !important;
  }
  .socialicons a {
    margin-left: 5px !important;
  }
  .btnPosts {
    margin-top: 10px;
  }
  .htmlMobile {
    flex-direction: column;
    place-items: start;
  }
  .Blogpostsfree p {
    font-size: 17px !important;
  }
  .category-heading h1 {
    font-size: 25px !important;
  }
  .mobileauthorsocials {
    position: absolute;
    top: 0px;
    right: 15px;
  }
  .authorImg {
    max-height: none !important;
  }
  .authorName {
    font-size: 32px !important;
  }
  .authorDescription {
    text-align: justify !important;
  }
  .smbl_heroHeader {
    display: flex;
    flex-direction: column-reverse;
  }
  .smbl_heroHeader h1 {
    font-size: 20px !important;
    text-align: center;
  }
  .smbl_heroHeader p {
    font-size: 14px !important;
  }
  .smbl_heroHeader label {
    font-size: 16px !important;
  }
  .smbl_heroHeader span {
    font-size: 14px;
  }
  .smblRequirement h2 {
    font-size: 20px !important;
    text-align: center;
  }
  .smblRequirement p {
    font-size: 14px !important;
    text-align: justify !important;
  }
  .smblRequirement li {
    font-size: 14px !important;
    text-align: justify !important;
  }
  .smblRequirement label {
    font-size: 14px !important;
    text-align: justify !important;
  }
  .smblRequirement ul {
    padding-left: 1rem !important;
  }
  .smblphonescreen1 {
    padding: 15% !important;
  }
  .smblIbadaSection p {
    font-size: 16px !important;
  }
  .smblIbadaSection label {
    font-size: 14px !important;
  }
  .smblIbadaSection span {
    font-size: 14px !important;
  }
  .smblRequirement img {
    max-height: 200px;
  }
}
.socialicons a {
  margin-left: 10px;
}
.wid-fill {
  width: -webkit-fill-available;
  height: 500px;
  max-width: 100%;
}
.Blog-header {
  width: 29%;
  box-shadow: 8px 8px 0px 0px #ececec;
  background: white;
}
.Blog-header p {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #203f59;
}
.topright-7020 {
  left: 69%;
  top: 34%;
}
.HeroDate {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #252b33;
}
.anchorOrange,
.anchorOrange a {
  /* font-size: 16px; */
  font-weight: 500;
  text-align: left;
  text-decoration: none;
}
.anchorOrange a {
  color: #ee720b;
}
.clr-black a {
  color: #203f59 !important;
  text-decoration: underline;
}
.blogSelect {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #e2e3e4;
  outline: none;
  padding: 5px;
}
.blogSearch {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #e2e3e4;
  outline: none;
  padding-left: 33px !important;
  padding: 5px;
}
.blogLine {
  border-top: 2px solid #f7f7f7 !important;
}
.postHeader {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #203f59;
}
.postPara {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #606066;
}
.postBox {
  box-shadow: 0px 0px 22px 0px #0000001a;
  transition: all 10s ease-in-out;
}
.postBtn {
  padding: 5px;
  margin-right: 10px;
  border-radius: 10px;
  border: none;
  background-color: #203f59;
  color: white;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0.5rem;
}
.postBtn a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
}
.postBtn:hover a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
}
.blogForm {
  border-radius: 20px;
  background-color: #203f59;
}
.blogForminput {
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0px;
  padding: 0px;
  outline: none;
}
.blogModalBtn {
  border-radius: 47px !important;
  border: none !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #ee720b !important;
  outline: none;
}
.bg-F7F7F7 {
  background-color: #f7f7f7;
}
.wid-max {
  width: max-content;
}
.wid-32 {
  width: 32% !important;
}
.top-40 {
  top: -40px;
}
.blogFooter p {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #ee720b;
}
.clr-orange {
  color: #ee720b;
}
.blogFooter h3 {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
}
.ps-6 {
  padding-left: 4.5rem;
}
.pagination-container .pagination {
  margin: 0px;
}
.pagination-container {
  display: flex;
  justify-content: center;
}
.pagination-container li {
  margin: 0.5rem;
  border: 0.5px solid #203f59;
  border-radius: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #203f59;
}
.pagination-container li:nth-of-type(2) {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 17px;
  padding-right: 17px;
}
.pagination-container .previous {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: none;
}
.pagination-container .previous a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #203f59;
}
.pagination-container .next a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #203f59;
}
.pagination-container .next {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: none;
}
.pagination-container .active {
  background-color: #203f59;
  color: white;
  font-size: 12px;
  font-weight: 400;
}
.blogfooterbg {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.searchBLog {
  left: 15px;
  top: 12px;
}
.postBox:hover .postHeader {
  color: #ee720b;
}
.postBtn:hover {
  background-color: #ee720b;
}
.postHeader,
.postBtn {
  transition: all 0.5s ease-out; /* Add a transition for all properties with a duration of 0.5 seconds */
}
.postHeaders {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #203f59;
}
.postDate {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justified;
  color: #606066;
}
.postDate b {
  color: #203f59 !important;
}
.btnPosts {
  color: white;
  background-color: #203f59;
  border: none;
  border-radius: 10px;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 10px;
}
.btnPosts a,
.btnPosts:hover a {
  text-decoration: none;
  color: white;
  background-color: #203f59;
  border: none;
  border-radius: 10px;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
}
.bg203F59 {
  background-color: #203f59;
}
.Blogpostsfree p {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0em;
  color: white;
  text-align: center;
}
.Blogpostsfree {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bor-10 {
  border-radius: 10px;
}
.category-heading h1 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #203f59;
}
.htmlMobile .schema-faq {
  display: none !important;
}
.htmlMobile img {
  max-width: 100%;
  height: auto;
}
.w-100 img {
  width: 100%;
}
.authorBlog a {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #203f59;
  text-decoration: none;
}
.BlogContent a {
  text-decoration: none;
}
.anchorOrange li {
  color: #606066;
  font-size: clamp(14px, 0.875rem + ((1vw - 3.2px) * 0.208), 16px);
}
.authorImage {
  height: 49px;
  border-radius: 50px;
  margin-right: 10px;
}
.authorImg {
  border-radius: 20px;
  max-height: 340px;
}
.authorName {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #203f59;
}
.authorDesignation {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0em;
  color: #252b33;
}
.authorDescription {
  font-size: 16px;
  font-weight: 400;
  color: #606066;
}
.contentImage {
  border-radius: 10px;
}
.blogFormHeader {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  color: white;
}
.freeQuoteBtn {
  position: relative;

  border: 3px ridge #ee720b;
  outline: none;
  background-color: transparent;
  color: white;
  transition: 1s;
  border-radius: 0.3em;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.freeQuoteBtn::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 3%;
  width: 95%;
  height: 40%;
  background-color: black;
  transition: 0.5s;
  transform-origin: center;
}

.freeQuoteBtn::before {
  content: "";
  transform-origin: center;
  position: absolute;
  top: 80%;
  left: 3%;
  width: 95%;
  height: 40%;
  background-color: black;
  transition: 0.5s;
}

.freeQuoteBtn:hover::before,
.freeQuoteBtn:hover::after {
  transform: scale(0);
}

.freeQuoteBtn:hover {
  box-shadow: inset 0px 0px 25px #ee720b;
}
.smbl_heroHeader h1 {
  font-size: 36px;
  font-weight: 600;
  color: #f5f5f5;
}
.smbl_heroHeader {
  background: #a4894b;
}
.smbl_heroHeader p {
  font-size: 16px;
  font-weight: 400;
  text-align: justified;
  color: #f3f3f3;
}
.smbl_heroHeader label {
  font-size: 20px;
  font-weight: 600;
  color: #f5f5f5;
}
.smbl_heroHeader span {
  font-size: 18px;
  font-weight: 400;
  color: #f3f3f3;
}
.clr203F59 {
  color: #203f59;
}
.clr203F59 label {
  color: #a4894b;
}
.smblRequirement h2 {
  font-size: 48px;
  font-weight: 600;
  color: #a4894b;
}
.smblRequirement p {
  font-size: 16px;
  font-weight: 400;
  text-align: justified;
  color: #5e6b81;
}
.smblRequirement li {
  font-size: 16px;
  font-weight: 400;
  text-align: justified;
  color: #5e6b81;
}
.smblRequirement label {
  font-size: 16px;
  font-weight: 600;
  text-align: justified;
  color: #5e6b81;
}
.smblphonescreen1 {
  background-color: #e5decd !important;
  text-align: center;
  box-shadow: 0px 12px 22px 0px rgba(0, 0, 0, 0.2);
  padding: 15%;
}
.smblphonescreen1 h3 {
  margin: 0;
}
.smblreviewlabel {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #a4894b;
}
.smblreview {
  font-size: 20px;
  font-weight: 600;
  color: #a4894b;
}
.topleft18519 {
  top: -150%;
  left: 19%;
}
.topright18517 {
  top: -150%;
  right: 17%;
}
.smblIbadaSection {
  background-color: #a4894b;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.smblIbadaSection p {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}
.smblIbadaSection label {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}
.smblIbadaSection span {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
@media (min-width: 768px) and (max-width: 1450px) {
  .smblIbadaSection {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .smblIbadaSection p {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }
  .smblIbadaSection label {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
  }
  .smblIbadaSection span {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
}
.ecommerceHeader {
  font-weight: 600;
  font-size: 3vw;
  color: black;
}
.clientText {
  font-weight: 700;
  font-size: 18px;
  color: #653aa3;
}
.descText {
  font-weight: 500;
  font-size: 1vw;
  color: black;
}
.descText::before {
  content: "My Pet Carnivore";
  background: linear-gradient(0deg, #efdd9d 50%, transparent 50%);
  font-weight: bold;
}

.ecommerceCard {
  border: 4px solid #d6656d30;
  border-radius: 17px;
}
.ecommerceCardText {
  font-size: 25px;
  font-weight: 500;
}
.mpc-idea {
  font-weight: 600;
  color: #b73d46;
  font-size: 30px;
}
.mpcBtn {
  background: #b73d46 !important;
  border-radius: 14px !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 17px;
}
.mpcClientTxt {
  font-weight: 400;
  color: black;
  font-size: 17px;
  font-family: "Poppins", sans-serif !important;
}
.arrow-animation {
  animation: slideLeftToRight 0.5s linear infinite alternate;
  height: 40px;
  padding: 8px;
}
@keyframes slideLeftToRight {
  0% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}
.play-button {
  position: absolute;
  top: 50%;
  left: 83%;
  transform: translate(-50%, -50%);
}
.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background: #2c2c2cd4; */
}
.video-container {
  position: absolute;
  top: 0%;
  left: 5%;
  bottom: 0%;
  right: 5%;
}
.videoClose {
  position: absolute;
  right: 5px;
  top: 6px;
  font-size: 24px;
  cursor: pointer;
  z-index: 9999;
  color: white;
}
.video-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.technology {
  font-weight: 600;
  color: #0e2b3d;
  font-size: 25px;
}
.ss-para {
  font-weight: 400;
  color: black;
  font-size: 17px;
}
.mpcImg {
  margin-left: 35%;
  transition: opacity 0.3s ease;
}
.divhover:hover .mpcImg {
  filter: brightness(80%) grayscale(0%) contrast(50%);
}

#content-div {
  z-index: 99;
}
.client-requirements {
  background-color: #f5f4ea;
}
.techMpcFont {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.mpc-animation {
  height: auto;
}
.no-after-quote::after {
  display: none;
}
.mpc-blockquote {
  font-family: Georgia, serif;
  position: relative;
  margin: 0.5em;
  padding: 0.1em 2em 0.1em 3em;
}

.mpc-blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 8em;
  line-height: 1;
  top: -30px;
  left: 42px;
  content: "\201C";
  color: #d6656d1f;
}
.purchasing-mpc {
  background-image: linear-gradient(to right, #f9fbf2, #fcdcdd);
}
.refdiv {
  background-image: url("/public/images/referral-bg.svg");
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 8% 2% 14% 2%;
  /* min-height:75vh */
}
.refHeading {
  font-size: 50px;
  font-weight: 600;
  color: #203f59;
}
.refText {
  font-size: 22px;
  font-weight: 500;
  color: #203f59;
}
.OrText {
  font-size: 45px;
  font-weight: 500;
  color: #203f59;
}
.referralForm {
  position: relative;
  background-color: #fbfbfb;
  box-shadow: 0px 4px 8px 0px #00000040;
  border-radius: 5px;
  z-index: 1;
}
.referralForm::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 15px;
  padding: 2px;
  /* background: linear-gradient(to top, #8293a2, #f3a742, #f48d63); */
  box-shadow: 0px 4px 8px 0px #00000040;

  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}
.refFormText {
  font-size: 20px;
  font-weight: 500;
  color: #203f59;
  z-index: 50;
  background-color: #fbfbfb;
}
.clientdiv {
  background-color: #203f59;
  padding: 50px 0px 50px 6px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
}
.referralBtn {
  background-color: #203f59;
  border: 1px solid #203f59;
  border-radius: 7px;
  color: white;
  padding: 7px 60px;
}
/* .calendly-inline-widget body{
  overflow: hidden;
} */
.calender {
  min-width: 310px;
  height: 610px;
}
.ss-ref {
  max-width: 100%;
  max-height: 130px;
}
.serviceTxt {
  font-size: 28px;
  font-weight: 500;
  color: #203f59;
}
.serviceDiv {
  background-color: #b601170a;
  margin: 2rem 0;
  padding: 2rem 0;
  /* padding: 0px 150px 90px 150px; */
}
.serviceDiv img {
  height: 175px !important;
  width: 950% !important;
}

.serviceDiv p {
  color: white !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  text-align: center;
  width: 100%;
}

.discount {
  font-size: 60px;
  color: #ff7200;
}
.highlight {
  display: inline-block;
  background: linear-gradient(0deg, #b7d1f5 50%, transparent 50%);
}
.customLabel {
  font-size: 15px;
  color: #203f59;
  font-weight: 400;
  padding: 0 3px;
  background: #fbfbfb;
  position: absolute;
  top: -12px;
  left: 17px;
}
.customInput {
  padding: 0.375rem 1.1rem !important;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: #fbfbfb;
  outline: none;
  color: inherit;
}
.customInput option {
  line-height: 2;
}
.customInput:focus {
  border-color: #80bcfc;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.crmQuote {
  background-color: #000724;
  border-radius: 21px;
}
.ecommerceQuote {
  background: linear-gradient(#5a7184 0%, #274d80 100%, #4772e2 100%);
  border-radius: 21px;
}
.erpQuote {
  background: radial-gradient(#01395c, #011634);
  border-radius: 21px;
}
.webappQuote {
  background-color: #0e1b3c;
  border-radius: 21px;
}
.text-hovered {
  color: white;
}
.common-component {
  position: relative;
  height: 100%;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease-in-out;
  border-radius: 6px;
}
.common-component.hovered {
  background-color: #203f59;
}
.crmaddress2 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 21px;
}
.crmheading {
  font-weight: 500;
  font-size: 30px;
}
.crmTechnology {
  font-weight: 600;
  font-size: 22px;
}
.setfontsize {
  font-size: 16px;
}
.zoom-container {
  padding-right: 3.5rem;
  padding-left: 4.5rem;
}
.crmBtnEffect {
  position: relative;
  display: inline-block;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.crmBtnEffect.learn-more {
  width: 12rem;
  height: auto;
}
.crmBtnEffect.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  /* background: #f7f7f7; */
  border-radius: 1.625rem;
  border: 2px solid white;
}
.crmBtnEffect.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  /* background: #f7f7f7; */
}
.crmBtnEffect.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.3rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.crmBtnEffect.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid white;
  border-right: 0.125rem solid white;
  transform: rotate(45deg);
}
.crmBtnEffect.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: white;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
}
.crmBtnEffect:hover .circle {
  width: 100%;
}
.crmBtnEffect:hover .circle .icon.arrow {
  background: white;
  transform: translate(1rem, 0);
}
.crmBtnEffect:hover .button-text {
  color: white;
}
.webkit-height {
  height: -webkit-fill-available;
}
.ecommerceSolutionHeader {
  font-size: 22px;
}
.ecommerce-component {
  position: relative;
  height: 100%;
  background-color: white;
  transition: background-color 0.3s ease-in-out;
  border: 4px solid #0e2b3d33;
  border-radius: 6px;
  padding: 22px 22px 24px 22px;
  margin: 20px;
}
.ecommerce-component.hovered {
  background-color: #203f59;
}
.ecommerceSolutionDesc {
  font-size: 16px;
  font-weight: 400;
}
.clr606066 {
  color: #606066;
}
.ecommerceBtn {
  background: white !important;
  border-radius: 14px !important;
  color: #4f6983 !important;
  font-weight: 700 !important;
  font-size: 17px !important;
}
.whyChooseUsDiv {
  background: rgb(255, 255, 255);
  border-radius: 15px;
  padding: 38px;
  height: 100%;
}
.whyChooseUsDiv:hover {
  box-shadow: 0px 2px 20px 0px #00000026;
}
.anchorOrange1 {
  color: #ee720b;
  text-decoration: none;
}
.anchorOrange1:hover {
  color: blue;
  text-decoration: none;
}
.mpcreferralheader {
  color: white;
  font-size: 40px;
}
.btn-mpcheader {
  color: white;
  border: none;
  background: #b60117;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 5px;
}
.btn-mpcheader:hover {
  color: #b60117;
  background-color: white;
  transition: all;
  transition-duration: 700ms;
}

.Herompcreferral {
  background-image: url("/public/images/MpcReferralBg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  height: 500px;
}
.Herok12referral {
  background-image: url("/public/images/k12referralbanner.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  height: 500px;
}
.mpcclient {
  font-size: 16px;
}
.herosectionspanmpcrefferal {
  font-size: 14px;
  border-radius: 10px;
  background: #ffffff1a;
  backdrop-filter: blur(15px);
  box-shadow: 0px 4px 9px 0px #b6011766 inset, 0px -4px 9px 0px #ffffff4d inset;
  padding: 20px 0;
}

.herosectionspank12refferal {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
  padding: 20px 10px;
  color: #a5cdf7;
  background: #393939;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  box-shadow: 3px 4px 4px 0px #a5cdf7 inset;
}
.mpcrefferalpage h2 {
  font-size: 24px;
  color: #b60117;
}
.CtaMpcRefferal {
  background-image: url("/public/images/ctampcrefferal.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  height: 300px;
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.Ctak12Refferal {
  background-image: url("/public/images/referralimages/image.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  height: 300px;
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.Ctak122Refferal {
  background-image: url("/public/images/k12ServicesBg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  height: 300px;
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.feedbackdiv {
  color: #8690ec;
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}
.LocationIndianaHeroBg {
  background-image: url("/public/images/LocationIndianaHeroBg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: transparent !important;
}
.IndianBgCta {
  background-image: url("/public/images/IndianBgCta.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding-left: 90px;
}
.IndianBgCta p {
  color: white;
  font-size: 28px;
  font-weight: 700;
}
.LocationIndianaHeroBg p {
  font-weight: 400 !important;
  font-size: 16px;
  color: #e6e6e6;
}
.locationHeader {
  font-size: 20px;
  font-weight: 600;
}
.borderradius-20 {
  border-radius: 20px;
}
@media only screen and (max-width: 1000px) {
  .IndianBgCta {
    background-size: auto;
    align-items: center;
    height: auto;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .IndianBgCta p {
    color: white;
    font-size: 20px;
    font-weight: 700;
  }
  .LocationIndianaHeroBg {
    background-size: cover;
  }
  .mpcrefferalpage h1 {
    font-size: 20px !important;
  }
  .mpcrefferalpage h2 {
    font-size: 18px !important;
  }
  .serviceDiv img {
    height: 100px !important;
  }
  .serviceDiv p {
    font-size: 16px !important;
  }
  .CtaMpcRefferal {
    font-size: 14px !important;
  }
  .Ctak12Refferal {
    font-size: 16px !important;
    height: 175px !important;
    background-size: auto 100% !important;
  }
  .Ctak122Refferal {
    font-size: 16px !important;
    height: 175px !important;
    background-size: auto 100% !important;
  }
  .herosectionspank12refferal {
    padding: 10px 5px;
  }

  .Herompcreferral {
    background-size: cover !important;
    height: 650px !important;
    background-image: url("/public/images/MpcReferralBgMobile.png") !important;
  }
  .Herok12referral {
    background-size: auto 100% !important;
    height: 420px !important;
  }
}
.mpcClients h2 {
  color: white !important;
  background: #b60117 !important;
}
