.hoverBtnEffect {
  position: relative;
  display: inline-block;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.hoverBtnEffect.learn-more {
  width: 12rem;
  height: auto;
}
.hoverBtnEffect.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  /* background: #f7f7f7; */
  border-radius: 1.625rem;
  border: 2px solid #203f59;
}
.hoverBtnEffect.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  /* background: #f7f7f7; */
}
.hoverBtnEffect.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.3rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.hoverBtnEffect.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #203f59;
  border-right: 0.125rem solid #203f59;
  transform: rotate(45deg);
}
.hoverBtnEffect.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #203f59;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
}
.hoverBtnEffect:hover .circle {
  width: 100%;
}
.hoverBtnEffect:hover .circle .icon.arrow {
  background: #203f59;
  transform: translate(1rem, 0);
}
.hoverBtnEffect:hover .button-text {
  color: #203f59;
}



