.new-client-comp-item {
  flex: 0 0 100%;
  opacity: 0;
  display: none;
  overflow: hidden;
  transition: opacity 0.3s ease; /* Add a smooth fade-in and fade-out effect */
}

.new-client-comp-item.active {
  opacity: 1;
  display: block; /* Show the active item */
}

.IndianaVideo {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.btnIndiana {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: center;
  background-color: white;
  border: none;
  color: #606066;
}
.IndianaContent p {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #203f59;
}
.IndianaContent b {
  color: #ee720b;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
}
.IndianaContent {
  color: #ee720b;
  font-weight: 400;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
