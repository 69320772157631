.image-carousel-container {
  max-width: 1000px; /* Set the maximum width of the carousel */
  margin: 0 auto;
}

.carousel-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.slick-dots {
  bottom: 20px; /* Adjust the position of the dots as needed */
}

.slick-dots li button:before {
  font-size: 0px;
}
.hw {
  height: 524px;
  width: 252px;
}
