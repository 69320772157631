.SitemapHeader h1 {
  text-align: center;
}

.SitemapHeader ul {
  list-style-type: none;
  padding: 0;
}

.SitemapHeader ul.category {
  margin-top: 20px;
  margin-bottom: 20px;
}

.SitemapHeader ul.category li {
  margin-bottom: 5px;
}

.SitemapHeader ul.category a {
  text-decoration: none;
  color: #0077b6;
}

.SitemapHeader ul.pages li,
.SitemapHeader ul.portfolio li,
.SitemapHeader ul.locations li {
  margin-bottom: 5px;
}

.SitemapHeader ul.pages a,
.SitemapHeaderul.portfolio a,
.SitemapHeader ul.locations a {
  text-decoration: none;
  color: #333;
}

.category-heading {
  font-weight: bold;
  margin-bottom: 10px;
}
