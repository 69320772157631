.sliderProgressContainer {
    height: 4px !important;
}

.sliderProgressContainer .bg-warning {
    background-color: #FF7200 !important;
}

.timeSliderHeader {
    padding: 22px;
    margin: 5px;
    border-radius: 20px
}

.timeSliderHeader.active {
    background-color: #F5F5F5;
}

.techFont {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.techClass {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.techNameClass {
    color: black;
    margin-top: 1.5rem !important;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bgNewGrey {
    --bs-bg-opacity: 1;
    background-color: #e9ecef !important;
}

.commonClassSliderHdn {
    opacity: 0;
    transition: all .75s ease-in;
}

.commonClassSliderHdn.show {
    opacity: 1;
}